<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-10">
            <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
            <!--<mat-icon>arrow_forward_ios</mat-icon>-->
            <a [routerLink]="['/testimonials']">Testimonials</a>
        </div>
        <div class="col-md-2 text-right">
            <!--<button id="pullright" mat-raised-button color="primary" routerLink="/hotels/create">Add Hotel</button>-->
        </div>
    </div>
</mat-card>

<mat-card>
    <div class="row">
        <div class="col-md-3">
            <mat-form-field class="text-left" style="width: 100%">
                <input
                    matInput
                    [(ngModel)]="params.customerEmail"
                    placeholder="Email Address"
                />
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field>
                <mat-select [(ngModel)]="params.sort" placeholder="Sort by">
                    <mat-option
                        [value]="sort.ID"
                        *ngFor="let sort of params.sorts"
                    >
                        {{ sort.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-3">
            <mat-form-field>
                <mat-select [(ngModel)]="params.limit" placeholder="Items per page">
                    <mat-option
                        [value]="l"
                        *ngFor="let l of params.limits"
                    >
                        {{ l }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col-md-3 text-right">
            <a mat-raised-button color="primary" (click)="search()">Search</a>
        </div>
    </div>
</mat-card>

<mat-card class="no-records" *ngIf="!testimonials.length">
    No Testimonials Listed
</mat-card>

<ng-container *ngIf="testimonials.length">
    <div class="margin-top-20">
        <app-pagination [params]="params" (update)="search()"></app-pagination>

        <table class="mat-elevation-z2 example-full-width data-listing">
            <thead>
                <tr>
                    <th style="width: 170px;">Created</th>
                    <th></th>
                    <th></th>
                    <th style="width: 200px;">Email</th>
                    <th>Comment</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let t of testimonials">
                    <td>{{ t.created | date: 'dd MM yyyy @ HH:mm' }}</td>
                    <td>
                        <i
                            class="fas fa-thumbs-up text-success"
                            *ngIf="t.rating === 1"
                        ></i>
                        <i
                            class="fas fa-thumbs-down text-danger"
                            *ngIf="t.rating === 0"
                        ></i>
                    </td>
                    <td>
                        <a  *ngIf="t.bookingId" [routerLink]="['/bookings/', t.bookingId]">
                            <i
                            class="fas fa-book"></i>

                        </a>
                    </td>


                    <td>{{ t.customerEmail }}</td>
                    <td>{{ t.comment }}</td>
                </tr>
            </tbody>
        </table>

        <app-pagination [params]="params" (update)="search()"></app-pagination>
    </div>
</ng-container>
