<ng-container *ngIf="!cardResponse">
    <div class="row">
        <div class="col-6">
            <p class="mb-0" >Card number</p>
        </div>
        <div class="col-6">
            <input type="search" class="form-control form-control-sm" [(ngModel)]="giftCard.number" autocomplete="new-password" />
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-6">
            <p class="mb-0" >PIN number</p>
        </div>
        <div class="col-6">
            <input type="text" class="form-control form-control-sm" [(ngModel)]="giftCard.pin" autocomplete="new-password" />
        </div>
    </div>
    <p *ngIf="cardError" class="text-danger" >{{cardError}}</p>

    <div class="row mt-2">
        <div class="col-6 text-left">
            <button class="btn btn-outline-primary btn-sm" type="button" (click)="toggleGiftCard()" [disabled]="processing">
                Cancel
            </button>
        </div>
        <div class="col-6 text-right">
            <button class="btn btn-primary btn-sm" type="button" (click)="giftCardCheckPoints()" [disabled]="processing">
                Apply <span *ngIf="processing">
                <i class="fa fa-spinner fa-spin"></i>
            </span>
            </button>
        </div>
    </div>

</ng-container>
<ng-container *ngIf="cardResponse">
    <div class="row">
        <div class="col-8">
            <p class="mb-0" >Card balance</p>
        </div>
        <div class="col-4 text-right">
            <p class="mb-0 accountTotal">{{(+cardResponse.Balance / +cardResponse.BalanceFactor) | currency : 'GBP'}}</p>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-8">
            <p class="mb-0" >How much would you like to redeem</p>
        </div>
        <div class="col-4">
            <input type="search" class="form-control form-control-sm" [(ngModel)]="giftCard.valueToRedeem" />
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-8">
            <p class="mb-0" >Remaining gift card balance</p>
        </div>
        <div class="col-4 text-right">
            <p class="mb-0 accountTotal">{{(+cardResponse.Balance / +cardResponse.BalanceFactor) -  +giftCard.valueToRedeem | currency : 'GBP'}}</p>
        </div>
    </div>

    <p *ngIf="cardError" class="text-danger" >{{cardError}}</p>

    <div class="row mt-2">
        <div class="col-6 text-right">
            <button class="btn btn-outline-primary btn-sm" type="button" (click)="toggleGiftCard()" [disabled]="processing">
                Cancel
            </button>
        </div>
        <div class="col-6 text-right">
            <button class="btn btn-primary btn-sm" type="button" (click)="applyPoints()" [disabled]="processing || !giftCard.valueToRedeem">
                Apply <span *ngIf="processing">
                <i class="fa fa-spinner fa-spin"></i>
            </span>
            </button>
        </div>
    </div>

</ng-container>

