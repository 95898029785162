<ng-container *ngIf="booking">
	<mat-card class="breadcrumb">
		<div class="row">
			<div class="col-md-10">
				<!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
				<!--<mat-icon>arrow_forward_ios</mat-icon>-->
				<a [routerLink]="['/bookings']">Bookings</a>
				<mat-icon>arrow_forward_ios</mat-icon>
				<a>View Booking</a>
			</div>
			<div class="col-md-2 text-right">
				<button id="pullright" class="btn btn-primary" routerLink="/bookings">Back</button>
			</div>
		</div>
	</mat-card>

	<mat-card class="breadcrumb">
		<div class="row">
			<div class="col-md-10">
				<button *ngIf="booking.idPrev !== ''" class="btn btn-primary"
						(click)="getBooking(booking.idPrev)">Previous
				</button>
			</div>
			<div class="col-md-2 text-right">
				<button *ngIf="booking.idNext !== ''" class="btn btn-primary"
						(click)="getBooking(booking.idNext)">Next
				</button>
			</div>
		</div>
	</mat-card>


	<div class="row">
		<div class="col-lg-5">
			<mat-card class="margin-bottom-30">
				<mat-card-header>
					<mat-card-title>Booking Details</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<table class="table example-full-width data-listing">
						<tbody>
                        <tr>
                            <th style="width: 170px;">Booking Status</th>
                            <td>{{booking.resStatus}}

                                <button *ngIf="booking.resStatus === 'Reserved'" type="button" class="ml-4" mat-raised-button color="warn" (click)="cancelConfirm()">
                                    Cancel Booking
                                    <i class="fa fa-spinner fa-spin ml-2" *ngIf="cancelling"></i>
                                </button>

                            </td>
                        </tr>
                        <tr>
							<th style="width: 170px;">Booking Created</th>
							<td>{{booking.created | date: 'dd/MM/yyyy HH:mm'}}</td>
						</tr>
						<tr *ngIf="!booking.payment.payUrl">
							<th style="width: 170px;">Stripe ID</th>
							<td>
                                <a *ngIf="booking.tokenId" class="hover" [href]="'https://dashboard.stripe.com/payments/' + booking.tokenId" target="_blank">{{booking.tokenId}}</a>

                            </td>
						</tr>
						<tr>
							<th style="width: 170px;">RR ID</th>
							<td>
                                <a class="hover" [href]="'https://rms.roomraccoon.com/admin/reservations/' + booking.rrId" target="_blank">{{booking.rrId}}</a>

                            </td>
						</tr>
						<tr>
							<th>Hotel / Room</th>
							<td>
								{{booking.hotel.name}} {{booking.room.name}}
							</td>
						</tr>
						<tr>
							<th>Check In</th>
							<td>{{booking.checkIn | date: 'dd/MM/yyyy'}}</td>
						</tr>
						<tr>
							<th>Check Out</th>
							<td>{{booking.checkOut | date: 'dd/MM/yyyy'}}</td>
						</tr>
						<tr>
							<th>Nights</th>
							<td>{{booking.nights}}</td>
						</tr>
						<tr>
							<th>Adults / Children</th>
							<td>{{booking.adults}} / {{booking.children}}</td>
						</tr>
						<tr>
							<th>Comments</th>
							<td>{{booking.comments}}</td>
						</tr>
						<tr *ngIf="booking.carReg">
							<th>Car registration</th>
							<td>{{booking.carReg}}</td>
						</tr>
						<!-- <tr>
							<th>Stripe ChargeID</th>
							<td>{{booking.chargeID}}</td>
						</tr> -->
						<tr *ngIf="booking.packageName">
							<th>Promotion</th>
							<td>{{booking.packageGroupName}}<br />{{booking.packageName}}</td>
						</tr>

                        <tr *ngIf="booking.voucherName">
							<th>Voucher</th>
							<td>{{booking.voucherName}}</td>
						</tr>

						<!-- <tr *ngIf="booking.hotel.name !== 'Beachside'">
							<th>Room Rate</th>
                            <td>
                                <div *ngFor="let rate of booking.rates; let idx = index">
                                    Night {{idx + 1}}: {{rate.roomRate | currency : 'GBP'}}
                                </div>


                            </td>
						</tr>
						<tr>
							<th>Passport discount</th>
							<td>{{booking.payment.discount_passport | currency : 'GBP'}}</td>
						</tr>
						<tr *ngIf="booking.giftCards">
							<th>Gift cards</th>
							<td>
                            <div *ngFor="let giftcard of booking.giftCards">
                                    {{giftcard.cardNumber}} - {{giftcard.pointsValue | currency : 'GBP'}}
                            </div>
							</td>
						</tr>
						<tr>
							<th>Total Gift card</th>
							<td>{{booking.payment.giftCard | currency : 'GBP'}}</td>
						</tr>

						<tr>
							<th>Total Charge</th>
							<td>{{booking.payment.gross | currency : 'GBP'}}</td>
						</tr>
						<tr>
							<th>Deposit Paid</th>
							<td>{{booking.payment.deposit | currency : 'GBP'}}</td>
						</tr>
						<tr>
							<th>Balance to Pay</th>
							<td>{{(booking.payment.gross - booking.payment.deposit) | currency : 'GBP'}}</td>
						</tr> -->
						</tbody>
					</table>
					<div *ngIf="booking.hasUpgrade" class="alert alert-success">Includes upgrade</div>

				</mat-card-content>
			</mat-card>

			<mat-card class="margin-bottom-30">
				<mat-card-header>
					<mat-card-title>Totals</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<table class="table example-full-width data-listing">
						<tbody>
						<tr>
							<td>Room Rate - {{booking.ratePlan}}</td>
							<td class="text-right">
                                <div>{{booking.payment.room_rate_total | currency : 'GBP'}}</div>
                                <ng-container *ngIf="booking.rates.length">
                                    <div *ngFor="let rate of booking.rates; let idx = index">
                                        Night {{idx + 1}}: {{rate.roomRate | currency : 'GBP'}}
                                    </div>

                                </ng-container>

                            </td>
						</tr>
						<tr *ngIf="booking.payment.child_supplement">
							<td>Child Supplement</td>
							<td class="text-right">{{booking.payment.child_supplement | currency : 'GBP'}}</td>
						</tr>
						<tr *ngIf="booking.payment.dog_supplement">
							<td>Dog Supplement</td>
							<td class="text-right">{{booking.payment.dog_supplement | currency : 'GBP'}}</td>
						</tr>
						<tr *ngIf="booking.payment.single_night_supplement">
							<td>Single Night Supplement</td>
							<td class="text-right">{{booking.payment.single_night_supplement | currency : 'GBP'}}</td>
						</tr>


                        <tr>
							<td>Events</td>
							<td class="text-right">{{booking.payment.event_cost | currency : 'GBP'}}</td>
						</tr>
						<tr>
							<td>Spa</td>
							<td class="text-right">{{booking.payment.spa | currency : 'GBP'}}</td>
						</tr>
						<tr>
							<td>Additional Items</td>
							<td class="text-right">{{booking.payment.product_cost | currency : 'GBP'}}</td>
						</tr>
						<tr>
							<td>Passport Discount</td>
							<td class="text-right">{{booking.payment.discount_passport | currency : 'GBP'}}</td>
						</tr>
						<tr>
							<td>Package Discount</td>
							<td class="text-right">{{booking.payment.discount_package | currency : 'GBP'}}</td>
						</tr>
						<tr *ngIf="booking.voucherName">
							<td>Voucher<br />
                                {{booking.voucherName}}</td>
							<td class="text-right">{{booking.payment.discount_voucher | currency : 'GBP'}}</td>
						</tr>
						<tr *ngIf="booking.giftCards">
							<th>Gift cards</th>
							<td>
                            <div *ngFor="let giftcard of booking.giftCards">
                                    {{giftcard.cardNumber}} - {{giftcard.pointsValue | currency : 'GBP'}}
                            </div>
							</td>
						</tr>

                        <tr>
							<td>Total</td>
							<td class="text-right">{{booking.payment.gross | currency : 'GBP'}}</td>
						</tr>
						<tr>
							<td>Deposit Paid</td>
							<td class="text-right">{{booking.payment.deposit | currency : 'GBP'}}</td>
						</tr>
						<tr>
							<td>Total to pay</td>
							<td class="text-right">{{(booking.payment.gross - booking.payment.deposit) | currency : 'GBP'}}</td>
						</tr>
						</tbody>
					</table>
				</mat-card-content>
			</mat-card>


			<mat-card class="margin-bottom-30">
				<mat-card-header>
					<mat-card-title>Additional Items</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<table class="table example-full-width data-listing">
						<thead>
						<tr>
							<th>Item Detail</th>
							<th class="text-right">Cost</th>
						</tr>
						</thead>
						<tbody>
						<tr *ngFor="let i of booking.items">
							<td>{{i.name}}</td>
							<td class="text-right">{{i.cost | currency : 'GBP'}}</td>
						</tr>
						</tbody>
					</table>
				</mat-card-content>
			</mat-card>

			<mat-card class="margin-bottom-30" *ngIf="booking.events.length">
				<mat-card-header>
					<mat-card-title>Events</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<table class="table example-full-width data-listing">
						<thead>
						<tr>
							<th>Event</th>
							<th>Date</th>
							<th>Tickets</th>
							<th class="text-right">Cost</th>
						</tr>
						</thead>
						<tbody>
						<tr *ngFor="let i of booking.events">
							<td>
                                {{i.eventName}}
                                <span *ngIf="i.skuName"> ({{i.skuName}})</span>
                            </td>
							<td>{{i.stDate}} {{i.stTime}}</td>
							<td>{{i.qty}}</td>
							<td class="text-right">{{i.gross | currency : 'GBP'}}</td>
						</tr>
						</tbody>
					</table>
				</mat-card-content>
			</mat-card>


		</div>
		<div class="col-lg-7">

			<mat-card class="margin-bottom-30">
				<mat-card-header>
					<mat-card-title>Customer Details</mat-card-title>
				</mat-card-header>
				<mat-card-content>
					<table class="table example-full-width data-listing">
						<tbody>
                            <tr>
                                <th style="width: 170px;">Passport number</th>
                                <td>
                                    <a [routerLink]="['/', 'passport', booking.customer.id]" class="hover">{{booking.customer.cardNumber}}</a>
                                </td>
                            </tr>
                            <tr>
							<th style="width: 170px;">Name</th>
							<td>{{booking.customer.firstname}} {{booking.customer.surname}}</td>
						</tr>
						<tr>
							<th>Email Address</th>
							<td>{{booking.customer.email}}</td>
						</tr>
						<tr>
							<th>Telephone</th>
							<td>{{booking.customer.telephone}}</td>
						</tr>
						<!-- <tr>
							<th>Gender</th>
							<td>{{booking.customer.gender}}</td>
						</tr> -->
						<tr>
							<th>Address</th>
							<td style="padding: 15px ">
								{{booking.customer.add1}}<span *ngIf="booking.customer.add2">, {{booking.customer.add2}}</span>, {{booking.customer.town}}, {{booking.customer.postcode}}
							</td>
						</tr>
						</tbody>
					</table>


				</mat-card-content>
			</mat-card>

            <ng-container *ngIf="spaBooking">
                <mat-card class="margin-bottom-30" *ngIf="booking.trybeOrderId">
                    <mat-card-header>
                        <mat-card-title>Treatment Booking</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <table class="table example-full-width data-listing">
                            <tbody>
                                <tr>
                                    <th style="width: 170px;">TryBe Booking Ref</th>
                                    <td>{{spaBooking.order_ref}} <a class="hover" [href]="'https://app.try.be/orders/' + spaBooking.id" target="_blank">Click to view</a></td>
                                </tr>
                                <tr>
                                    <th style="width: 170px;">Total Value</th>
                                    <td>{{booking.payment.spa | currency : 'GBP'}}</td>
                                </tr>
                                <tr>
                                    <th>Intake Form</th>
                                    <td>
                                        <a class="hover" [href]="spaBooking.intake_form_url" target="_blank">Click to view</a>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </mat-card-content>
                </mat-card>

                <mat-card class="mt-2 margin-bottom-30" *ngFor="let b of spaBooking.booking_items">
                    <mat-card-header>
                        <mat-card-title style="font-size: 16px;">Treatment: {{b.booking_summary.offering.name}} - {{b.booking_summary.start_time | date: "dd/MM/yyyy @ HH:mm"}}</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <table class="table example-full-width data-listing">
                            <tbody>
                                <!-- <tr>
                                    <th>Treatment</th>
                                    <td>{{b.booking_summary.offering.name}}</td>
                                </tr>
                                <tr>
                                    <th>Date/Time</th>
                                    <td>{{b.booking_summary.start_time | date: "dd/MM/yyyy @ HH:mm"}}</td>
                                </tr> -->
                                <tr>
                                    <th>Duration</th>
                                    <td>{{b.booking_summary.duration}} mins</td>
                                </tr>
                                <tr>
                                    <th>Room</th>
                                    <td>{{b.booking_summary.room.name}}</td>
                                </tr>
                                <tr *ngFor="let p of b.booking_summary.practitioners">
                                    <th>Practitioner</th>
                                    <td>{{p.name}}</td>
                                </tr>
                                <tr>
                                    <th>Guest</th>
                                    <td>{{b.guest.name}}</td>
                                </tr>
                                <tr>
                                    <th>Price</th>
                                    <td>{{(b.price / 100) | currency : 'GBP'}}</td>
                                </tr>
                            </tbody>
                        </table>


                    </mat-card-content>
                </mat-card>

            </ng-container>

            <ng-container *ngIf="(user$ | async)?.permissions['createBooking']">
                <mat-card class="margin-bottom-30">
                    <mat-card-header>
                        <mat-card-title>Payment History</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="alert alert-success px-3" *ngIf="!booking.payment.unpaid">
                            <p class="mb-0">This booking has no outstanding amount to pay</p>
                        </div>
                        <div class="alert alert-danger px-3" *ngIf="booking.payment.unpaid">
                            <p class="mb-0">This booking has an outstanding balance of {{booking.payment.unpaid | currency: 'GBP'}}</p>
                        </div>

                        <table class="table example-full-width data-listing">
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th align="right">Amount</th>
                                    <th align="right">Last four</th>
                                    <th align="right">Expiry</th>
                                    <th>Success?</th>
                                    <!-- <td>Reason</td> -->
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let p of booking.paymentHistory">
                                    <tr [ngClass]="{'bg-danger' : p.type === 'refund'}">
                                        <td>{{p.createdAt}}</td>
                                        <td align="right"><span *ngIf="p.type === 'refund'">-</span>{{p.paid | currency : 'GBP'}}</td>
                                        <td align="right">{{p.cardSummary}}</td>
                                        <td align="right">{{p.expiryDate}}</td>
                                        <td align="center" *ngIf="p.webhookId">
                                            <i *ngIf="p.success" class="fa fa-check-circle text-success"></i>
                                            <i *ngIf="!p.success" class="fa fa-times-circle text-danger"></i>
                                        </td>
                                        <!-- <td *ngIf="!p.webhookId">
                                            <i class="fa fa-spinner fa-spin"></i>
                                        </td>
                                        <td *ngIf="p.webhookId">
                                            <span *ngIf="p.type === 'refund'">REFUND - </span>{{p.reason}}
                                        </td>
                                        <td *ngIf="!p.webhookId">
                                            <span *ngIf="p.type === 'refund'">REFUND - </span>Pending
                                        </td> -->
                                        <td>
                                            <button *ngIf="p.type === 'payment' && p.paid > 0 && p.cardSummary !== 'Stripe'" class="btn btn-warning btn-sm" (click)="triggerRefund(p)">Refund</button>
                                        </td>
                                    </tr>
                                    <tr style="height: unset;" *ngIf="p.payUrlId">
                                        <td style="padding: 0; font-size: 11px;"></td>
                                        <td style="padding: 0; font-size: 11px;" colspan="3"><a [href]="p.payUrl" target="_blank">{{p.payUrl}}</a></td>
                                        <td style="padding: 0; font-size: 11px;" colspan="3">{{p.payUrlExpires}}</td>
                                    </tr>
                                </ng-container>

                            </tbody>
                        </table>
                    </mat-card-content>
                </mat-card>

                <mat-card class="margin-bottom-30" *ngIf="booking.resStatus !== 'Cancelled' && booking.payment.unpaid">
                    <mat-card-header>
                        <mat-card-title>Payment Processing</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <hr />
                        <p class="mb-2">This customer has {{cards.length}} stored cards on file.</p>

                        <div class="text-center">
                            <p *ngIf="cardSelected">Confirm payment value and then click 'Make Payment'</p>
                            <div class="row">
                                <div class="col-4 text-left">
                                    <p>Confirm payment value</p>
                                    <!-- <button mat-raised-button color="danger" (click)="requestingPayment = false; paymentResult = undefined">Cancel</button> -->
                                </div>
                                <div class="col-4">
                                    <mat-form-field class="text-left" style="width: 100%">
                                        <input matInput [(ngModel)]="paymentData.valueToDisplay" (keyup)="lockPayAmount()" placeholder="Enter amount £">
                                    </mat-form-field>
                                </div>
                                <div class="col-4 text-right">
                                    <!-- <button mat-raised-button color="warn" (click)="makePayment()">Make Payment</button> -->
                                </div>
                            </div>

                        </div>


                        <p class="mb-2">
                            Please select how you would like to take payment.
                        </p>

                        <div class="row mt-3">
                            <div class="col-4 text-left">
                                <button *ngIf="cards.length" type="button" class="btn btn-sm btn-primary" (click)="setPaymentMethod('stored')">Pay by stored card</button>
                            </div>
                            <div class="col-4 text-center">
                                <button type="button" class="btn btn-sm btn-primary" (click)="setPaymentMethod('link')">Pay by payment Link</button>
                            </div>
                            <div class="col-4 text-right">
                                <button type="button" class="btn btn-sm btn-primary ml-4" (click)="setPaymentMethod('card')">Pay by credit/debit card</button>
                            </div>
                        </div>

                    </mat-card-content>
                </mat-card>

            </ng-container>


        </div>
	</div>
    <app-confirmation *ngIf="this.confirmationData.open" (complete)="confirmAction($event)" [data]="confirmationData"></app-confirmation>

    <app-payment-modal *ngIf="showPaymentModal" [paymentData]="paymentData" (complete)="paymentComplete($event)"></app-payment-modal>
    <app-adyen-refund *ngIf="showRefundModal" [refundData]="refundData" (complete)="refundComplete($event)"></app-adyen-refund>
</ng-container>

