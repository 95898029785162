import { BookingService } from './../../services/booking.service';
import { Store } from './../../store';
import { AvailabilityService } from './../../services/availability.service';
import { OverlayService } from './../../services/overlay.service';
import { BasketService } from './../../services/basket.service';
import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { VoucherService } from 'src/app/services/voucher.service';
import { BasketTotalsService } from 'src/app/services/basket-totals.service';
import { ToolsService } from 'src/app/services/tools.service';
import { AdyenService } from 'src/app/services/adyen.service';

@Component({
    selector: 'app-booking-payment',
    templateUrl: './booking-payment.component.html',
    styleUrls: ['./booking-payment.component.css']
})
export class BookingPaymentComponent implements OnInit {
    basket$ = this.store.select<any>('basket');

    vouchersAvailable$ = this.store.select<any[]>('vouchersAvailable');
    availabilitySearch$ = this.store.select<any>('availabilitySearch');

    showCheckoutForm = true;
    usernameAlt = '';
    emailEdit = false;

    depositFixed = false;
    depositAmount = 100;

    basket;
    cards = [];
    cardErrors = [];

    paymentLinkData = undefined;
    paymentData = undefined;
    showPaymentModal = false;

    hotel$ = this.store.select<any>('hotel');
    hotel;

    roomId = '';

    customer = {
        email: '',
        firstname: '',
        surname: '',
        stripeId: '',
    }
    paymentLinkUrl = '';
    lineItems = [];
    shopper = {
        name: '',
        email: '',
        emailAlt: '',
        firstname: ''
    }

    addingCard = false;

    user;

    showGiftCardEntry = false;
    giftCardChargeError = '';

    constructor(
        private bookingService: BookingService,
        private store: Store,
        private basketService: BasketService,
        private basketTotalsService: BasketTotalsService,
        private router: Router,
        private fb: UntypedFormBuilder,
        private availabilityService: AvailabilityService,
        private voucherService: VoucherService,
        private toolsService: ToolsService,
        private adyenService: AdyenService,
        private overlayService: OverlayService,
    ) { }

    ngOnInit(): void {

        let basket = this.store.selectForLocal('basket');
        basket.voucher = undefined;
        console.log(basket.items[0].checkoutTotals.discount);

        basket = this.basketTotalsService.totalBasketMultiCheckout(basket);
        const availabilitySearch = this.store.selectForLocal('availabilitySearch')

        this.store.set('basket', basket);

        this.basket = this.store.selectForLocal('basket');
        if (!this.basket.items.length) {
            this.router.navigate(['/'])
        }

        this.hotel = availabilitySearch.hotel;
        this.getUserVouchers();
        console.log(this.hotel)
        if (!this.hotel.allowPaymentChoice) {
            this.showCheckoutForm = true;
        }

        if (this.basket.items.length) {
            this.roomId = this.basket.items[0].room.id;
        }
        this.toggleDeposit('full')

        this.basketTotalsService.totalBasketMultiCheckout(this.basket);
        this.basket = this.store.selectForLocal('basket');
        this.buildLineItems();
        this.buildShopper();
        this.getUserCards();
        const profile = this.store.selectForLocal('profile');

    }

    selectVoucher(voucher) {
        const vouchersAvailable = this.store.selectForLocal('vouchersAvailable');

        for (let i = 0; i < vouchersAvailable.length; i++) {
            if (voucher.id === vouchersAvailable[i].id) {
                vouchersAvailable[i].selected = true;
                this.basket.voucher = vouchersAvailable[i];
            } else {
                vouchersAvailable[i].selected = false;
            }
        }

        this.basketTotalsService.totalBasketMultiCheckout(this.basket);
        this.store.set('basket', this.basket);

        this.store.set('vouchersAvailable', vouchersAvailable);
    }

    removeVoucher(voucher) {
        const vouchersAvailable = this.store.selectForLocal('vouchersAvailable');
        this.basket.voucher = undefined;

        for (let i = 0; i < vouchersAvailable.length; i++) {
            if (voucher.id === vouchersAvailable[i].id) {
                vouchersAvailable[i].selected = false;
            }
        }
        this.basketTotalsService.totalBasketMultiCheckout(this.basket);
        this.store.set('basket', this.basket);

        this.store.set('vouchersAvailable', vouchersAvailable);
    }


    toggleGiftCard() {
        if (!this.showGiftCardEntry) {
            this.showGiftCardEntry = true;
        } else {
            this.showGiftCardEntry = false;
        }
    }

    removeGiftCard(idx) {
        this.basket.giftCards.splice(idx, 1);

        this.basket = this.basketTotalsService.totalBasketMultiCheckout(this.basket);
    }

    toggleDeposit(v) {
        const basket = this.store.selectForLocal('basket');
        this.basket.depositPaymentType = v;

        if (v === 'full') {
            this.basket.fullPaymentSelected = true;
        } else {
            this.basket.fullPaymentSelected = false;
        }
        if (v !== 'part') {
            this.basket.checkoutTotals.fixed = 0;
        }
        // this.store.set('basket', basket);
        for (let i = 0; i < this.basket.items.length; i++) {
            this.basket.items[i].fullPaymentSelected = this.basket.fullPaymentSelected;
            this.basket.items[i].depositPaymentType = v;
            console.log(this.depositAmount, this.basket.items[i].checkoutTotals.deposit.checkout)

        }

        this.basket = this.basketTotalsService.totalBasketMultiCheckout(this.basket);
        this.showCheckoutForm = true;
    }
    applyFixedDeposit() {
        console.log('HDHD', this.basket.checkoutTotals.deposit.fixed, +this.basket.depositFixedValue)
        this.basket.depositFixedValue = this.depositAmount;

        for (let i = 0; i < this.basket.items.length; i++) {
            this.basket.items[i].depositFixed = true;
        }
        this.basket = this.basketTotalsService.totalBasketMultiCheckout(this.basket);
        this.showCheckoutForm = true;
    }

    intentCreate() {
        let amountToPay = Math.round((+this.basket.checkoutTotals.paid.card * 100) * 100) / 100;

        // amountToPay = 300;
        const user = this.store.selectForLocal('user');
        if (user && user.username === '7becfd60-8489-4314-bbc8-fc33f631e575') {
            amountToPay = 100;
        }

        // this.basket.totals.paid = this.basket.totals.deposit;

        // if (hotel.fullPaymentRequired) {
        //     amountToPay = Math.ceil((+this.basket.totals.total - +this.basket.totals.discount) * 100);
        //     this.basket.totals.paid = (+this.basket.totals.total - +this.basket.totals.discount);
        // }
        // this.store.set('basket', this.basket);

        const profile = this.store.selectForLocal('profile');
        const intentCustomer = {
            email: profile.username,
            firstname: profile.firstname,
            surname: profile.surname,
        }

    }


    finalRoomCheck() {
        this.availabilityService.finalRoomCheck('webapp').subscribe(data => {
            if (!data.roomAvailable) {
                // this.setStep.emit('roomTaken');
            }
        })
    }

    complete() {

        // this.bookingService.createNew(this.mCard).subscribe(data => {
        //     this.basketService.closeBasket();
        //     this.processing = false;
        //     this.getUserVouchers();
        //     // this.setStep.emit('complete')
        // })

    }

    getUserVouchers() {
        const user = this.store.selectForLocal('user');
        const room = this.store.selectForLocal('room');
        const basket = this.store.selectForLocal('basket');

        let userId = undefined;
        let hotelId = '';
        let roomId = 'na';
        let checkInDay = 0;

        if (user) {
            userId = user.id;
        }
        if (this.hotel) {
            hotelId = this.hotel.id;
        }
        if (room) {
            roomId = room.id;
        }
        if (basket) {
            checkInDay = basket.items[0].availabilitySearch.checkInDay;
        }

        if (user) {
            this.voucherService.getUserVouchers(userId, hotelId, roomId, checkInDay).subscribe(data => {
            })
        }

    }


    back() {
        // this.setStep.emit('addons')
    }

    prePaymentFinalRoomCheckNoCharge() {
        this.giftCardChargeError = '';
    }

    testBooking() {
        this.createBooking()
    }

    prePaymentFinalRoomCheck() {

        this.giftCardChargeError = '';
        this.availabilityService.finalRoomCheck('webapp').subscribe(data => {
            if (data.roomAvailable) {
                if (this.basket.giftCards && this.basket.giftCards.length) {
                    this.bookingService.giftCardPurchase(this.basket).subscribe(data => {
                        this.basket = data.data.basket;

                        for (let e = 0; e < this.basket.length; e++) {
                            if (this.basket[e].result.description && this.basket[e].result.status) {
                                this.giftCardChargeError = this.basket[e].result.description;
                            }
                        }
                        if (!data.data.success) {
                            this.giftCardChargeError = 'Sorry, there was an error processing your giftcard';
                        }

                        if (data.data.success) {
                            this.store.set('basket', this.basket);
                            if (+this.basket.checkoutTotals.paid.card === 0) {
                                this.createBooking();
                            } else {
                                // this.buy();
                            }
                        } else {
                        }


                    })
                } else {
                    // this.buy();
                }

            } else {
                // this.setStep.emit('roomTaken');
            }
        })

    }


    createBooking() {
        this.overlayService.setProcessing({ open: true, title: 'Sending booking to Room Raccoon....' })
        this.bookingService.createMultiCheckout().subscribe(data => {
            console.log(data);
            this.overlayService.setProcessing({ open: false, title: '' })
            this.router.navigate(['/bookings/create/complete'])
        })
    }


    createPaymentLink(rrId) {

        const basket = this.store.selectForLocal('basket');
        let amountToPay = basket.checkoutTotals.paid.card;

        const amountToPayDisplay = basket.checkoutTotals.paid.card;

        amountToPay = Math.round((amountToPay * 100) * 100) / 100;


        let shopperEmail = basket.user.username;
        if (this.usernameAlt) {
            shopperEmail = this.usernameAlt;
        }
        console.log(this.usernameAlt);

        const linkData = {
            value: amountToPay,
            valueDisplay: basket.checkoutTotals.paid.card,
            shopper: {
                name: `${basket.user.firstname} ${basket.user.surname}`,
                email: shopperEmail,
                firstname: basket.user.firstname
            },
            lineItems: this.lineItems,
            userId: basket.user.id,
            hotelId: basket.items[0].availabilitySearch.hotel.id,
            hotelName: basket.items[0].availabilitySearch.hotel.name,
            roomId: basket.items[0].availabilitySearch.room.id,
            reference: `${basket.items[0].availabilitySearch.hotel.name} room booking`,
            expiryHours: 2,
            metadata: {
                bookingId: 'new',
                bookingLink: this.toolsService.newUUID()
            }
        }

        console.log(linkData)
        this.adyenService.createPaymentLink(linkData).subscribe(data => {
            this.paymentLinkUrl = data.url;
            basket.payUrlId = data.id;
            basket.payUrl = data.url;
            basket.payUrlExpires = data.expiresAt
            this.basketService.saveBasket(basket);

            this.createBooking();
            console.log(data);
            // this.router.navigate(['/bookings/create/complete'])
        })

    }

    saveBasketToDB() {
        this.basketService.saveBasketToDB(this.basket).subscribe(data => {
        })
    }


    applyCsDiscount() {
        this.basket = this.basketTotalsService.totalBasketMultiCheckout(this.basket);
        console.log(this.basket);
        this.basketService.saveBasket(this.basket);

    }

    getUserCards() {
        const d = {
            userId: this.basket.user.id,
            hotelId: this.basket.items[0].availabilitySearch.hotel.id,
            roomId: this.basket.items[0].availabilitySearch.room.id,
        }

        this.adyenService.getUserCards(d).subscribe(data => {
            this.cards = data;
            console.log(this.cards)
        })
    }

    buildLineItems() {
        for (let i = 0; i < this.basket.items.length; i++) {
            const roomCost = Math.round((+this.basket.items[i].checkoutTotals.costs.room * 100) * 100) / 100;
            this.lineItems.push(
                { quantity: 1, amountIncludingTax: roomCost, description: this.basket.items[i].availabilitySearch.hotel.name + ' ' + this.basket.items[i].availabilitySearch.room.name }
            )

            for (let a = 0; a < this.basket.items[i].addOns.length; a++) {
                this.lineItems.push(
                    { quantity: this.basket.items[i].addOns[a].qtySelected, amountIncludingTax: Math.round((+this.basket.items[i].addOns[a].totalCost * 100) * 100) / 100, description: this.basket.items[i].addOns[a].name }
                )
            }

            if (this.basket.items[i].checkoutTotals.discount) {
                this.lineItems.push(
                    { quantity: 1, amountIncludingTax: -(Math.round((+this.basket.items[i].checkoutTotals.discount.total * 100) * 100) / 100), description: 'Discount' }
                )

            }
        }
    }

    buildShopper() {
        let shopperEmail = this.basket.user.username;
        if (this.usernameAlt) {
            shopperEmail = this.usernameAlt;
        }
        console.log(this.usernameAlt);

        this.shopper.name = `${this.basket.user.firstname} ${this.basket.user.surname}`;
        this.shopper.email = shopperEmail;
        this.shopper.firstname = this.basket.user.firstname;
    }


    setPaymentMethod(method) {

        const value = Math.round((+this.basket.checkoutTotals.paid.card * 100) * 100) / 100;
        const valueToDisplay = +this.basket.checkoutTotals.paid.card;

        console.log(this.paymentLinkData)

        this.paymentData = {
            sendBookingLink: false,
            method,
            value,
            valueToDisplay,
            shopper: this.shopper,
            lineItems: this.lineItems,
            user: this.basket.user,
            hotel: { id: this.basket.items[0].availabilitySearch.hotel.id, name: this.basket.items[0].availabilitySearch.hotel.name },
            room: { id: this.basket.items[0].availabilitySearch.room.id, name: this.basket.items[0].availabilitySearch.room.name },
            reference: `${this.basket.items[0].availabilitySearch.hotel.name} room booking`,
            isBooking: true,
            result: undefined,
            metadata: {
                bookingId: 'new',
                bookingLink: this.toolsService.newUUID(),
                method: method
            }

        }
        console.log(this.paymentData)
        this.showPaymentModal = true;

    }

    adyenPaymentSuccess(e) {
        console.log(e)
        this.showPaymentModal = false;
    }
    paymentComplete(e) {
        console.log(e)
        this.showPaymentModal = false;
        if (e.action) {
            this.overlayService.setProcessing({ open: true, title: 'Sending booking to Room Raccoon....' })
            this.basket.adyen = e.paymentResult;
            this.store.set('basket', this.basket);
            this.createBooking();
        }
    }

}

