<mat-card class="breadcrumb">
    <div class="row">
        <div class="col-md-7">
            <!--<a [routerLink]="['/dashboard']">Dashboard</a>-->
            <!--<mat-icon>arrow_forward_ios</mat-icon>-->
            <a [routerLink]="['/rateplans']">Rate Plans</a>
        </div>
        <div class="col-md-5 text-right">
            <a mat-raised-button color="primary" (click)="create()">Create Rate Plan</a>
        </div>
    </div>
</mat-card>


<div class="mb-3 mt-3">


    <div class="mat-card mt-2 p-2">
        <div class="row">
            <div class="col-2">
                <mat-form-field class="mb-0">
                    <mat-label>Plan name</mat-label>
                    <input matInput type="text" autocomplete="off" name="searchText" [(ngModel)]="params.searchText" (keyup)="filterSearch()">
                </mat-form-field>
            </div>
            <div class="col-2">
                <mat-form-field>
                    <mat-select
                        [(ngModel)]="params.hotel"
                        (selectionChange)="filterSearch()"
                        placeholder="hotels">
                        <mat-option [value]="undefined">All Hotels</mat-option>
                        <mat-option
                            [value]="sy.id"
                            *ngFor="let sy of (hotels$ | async)">
                            {{sy.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-md-2">
                <mat-form-field style="display: block">
                    <input type="search" matInput (dateChange)="filterSearch()" [(ngModel)]="startDate" (click)="startpicker.open()" [matDatepicker]="startpicker" placeholder="Check in from">
                    <mat-datepicker #startpicker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field style="display: block">
                    <input type="search" matInput (dateChange)="filterSearch()" [(ngModel)]="endDate" (click)="endpicker.open()" [matDatepicker]="endpicker" placeholder="Check in to">
                    <mat-datepicker #endpicker></mat-datepicker>
                </mat-form-field>
            </div>


            <div class="col-md-2">
                <mat-form-field style="display: block">
                    <input type="search" matInput (dateChange)="filterSearch()" [(ngModel)]="bookingStartDate" (click)="bookingStartpicker.open()" [matDatepicker]="bookingStartpicker" placeholder="Booking date from">
                    <mat-datepicker #bookingStartpicker></mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field style="display: block">
                    <input type="search" matInput (dateChange)="filterSearch()" [(ngModel)]="bookingEndDate" (click)="bookingEndpicker.open()" [matDatepicker]="bookingEndpicker" placeholder="Booking date to">
                    <mat-datepicker #bookingEndpicker></mat-datepicker>
                </mat-form-field>
            </div>
</div>
    <div class="row">
            <div class="col-2">
                <mat-form-field>
                    <mat-select
                        [(ngModel)]="params.status"
                        (selectionChange)="filterSearch()"
                        placeholder="Active Status">
                        <mat-option [value]="undefined">All Status</mat-option>
                        <mat-option [value]="1">Active</mat-option>
                        <mat-option [value]="0">Inactive</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="col-2">
                <mat-form-field>
                    <mat-select
                        [(ngModel)]="params.isDerivative"
                        (selectionChange)="filterSearch()"
                        placeholder="Derivatives">
                        <mat-option [value]="undefined">All Types</mat-option>
                        <mat-option [value]="1">Derivative</mat-option>
                        <mat-option [value]="0">Non Derivative</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <!-- <div class="col-2 text-right">
                <mat-form-field class="mb-0">
                    <mat-label> </mat-label>
                    <mat-select [(ngModel)]="params.limit" (ngModelChange)="filterSearch()">
                        <mat-option [value]="l" *ngFor="let l of params.limits">{{l}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div> -->

            <div class="col-md-8 text-right">
                <a mat-raised-button color="warn" (click)="resetParams()">Clear</a>
            </div>

        </div>
    </div>


  </div>

  <div class="mat-card mt-2 p-2">
    <div class="card mb-3 no-records" *ngIf="!loading && !data.length">
        No results for the search criteria entered
    </div>
    <div class="card mb-3 no-records" *ngIf="loading">
        Loading data <i class="fa fa-spinner fa-spin"></i>
    </div>


    <div *ngIf="data.length">


        <table class="example-full-width data-listing">
            <thead>
            <tr>
                <th>
                    <a *ngIf="params.sort === 'rate_plans.name DESC'" (click)="setSort('rate_plans.name')">Plan Code <i class="fa fa-angle-up"></i></a>
                    <a *ngIf="params.sort !== 'rate_plans.name DESC'" (click)="setSort('rate_plans.name DESC')">Plan Code <i class="fa fa-angle-down"></i></a>
                </th>
                <th>
                    <a *ngIf="params.sort === 'rate_plans.name DESC'" (click)="setSort('rate_plans.name')">Plan Name <i class="fa fa-angle-up"></i></a>
                    <a *ngIf="params.sort !== 'rate_plans.name DESC'" (click)="setSort('rate_plans.name DESC')">Plan Name <i class="fa fa-angle-down"></i></a>
                </th>
                <th>
                    <a *ngIf="params.sort === 'hotel.name DESC'" (click)="setSort('hotel.name')">Hotel <i class="fa fa-angle-up"></i></a>
                    <a *ngIf="params.sort !== 'hotel.name DESC'" (click)="setSort('hotel.name DESC')">Hotel <i class="fa fa-angle-down"></i></a>
                </th>
                <th>
                    <a *ngIf="params.sort === 'rate_plans.status DESC'" (click)="setSort('rate_plans.status')">Active <i class="fa fa-angle-up"></i></a>
                    <a *ngIf="params.sort !== 'rate_plans.status DESC'" (click)="setSort('rate_plans.status DESC')">Active <i class="fa fa-angle-down"></i></a>
                </th>
                <th>
                    <a *ngIf="params.sort === 'rate_plans.isDerivative DESC'" (click)="setSort('rate_plans.isDerivative')">Derivative <i class="fa fa-angle-up"></i></a>
                    <a *ngIf="params.sort !== 'rate_plans.isDerivative DESC'" (click)="setSort('rate_plans.isDerivative DESC')">Derivative <i class="fa fa-angle-down"></i></a>
                </th>
                <th>Bookings</th>
                <th>Spend</th>

                <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let data of data; let i = index" [ngClass]="{'tr-odd': isOdd(i)}">
                <td class="hover" (click)="update(data)">
                    {{data.ratePlanCode}}
                </td>
                <td class="hover" (click)="update(data)">
                    {{data.name}}
                </td>
                <td class="hover" (click)="update(data)">
                    {{data.hotelName}}
                </td>
                <td style="width: 175px">
                    <a class="hover" (click)=toggleStatus(data)><mat-icon *ngIf="data.status" class="text-success">check</mat-icon></a>
                    <a class="hover" (click)=toggleStatus(data)><mat-icon *ngIf="!data.status" class="text-danger">remove</mat-icon></a>
                </td>
                <td style="width: 175px">
                    <mat-icon *ngIf="data.isDerivative" class="text-success">check</mat-icon>
                    <mat-icon *ngIf="!data.isDerivative" class="text-danger">remove</mat-icon>
                </td>
                <td class="hover" (click)="update(data)">
                    {{data.totalBookings}}
                </td>
                <td class="hover" (click)="update(data)">
                    {{data.totalSpend | currency: 'GBP'}}
                </td>


                <!-- <td class="d-none d-xl-table-cell">{{data.createdAt | date:'dd MMM yyyy'}}</td> -->
                  <td class="text-right">
                      <button mat-icon-button [matMenuTriggerFor]="menu">
                          <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu">
                          <button mat-menu-item (click)="update(data)">
                              <mat-icon>edit</mat-icon>
                              <span>Edit</span>
                          </button>
                        <button mat-menu-item (click)="delete(data)">
                              <mat-icon>delete</mat-icon>
                              <span>Delete</span>
                          </button>
                      </mat-menu>

                      <!-- <a [href]="'https://rooms.norfolkpassport.com/rateplans/' + data.eventType.slug + '/' + data.slug" target="_blank" class="hover ml-3">
                        <i class="fa fa-link"></i>
                    </a> -->

                    </td>
            </tr>
            </tbody>
        </table>

        <app-pagination [params]="params" (update)="search()"></app-pagination>

    </div>

  </div>

  <app-confirmation *ngIf="dataToDelete" [data]="deleteConfirmation" (complete)="deleteAction($event)"></app-confirmation>
