import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-room-cost-multiday',
    templateUrl: './room-cost-multiday.component.html',
    styleUrls: ['./room-cost-multiday.component.css']
})
export class RoomCostMultidayComponent implements OnInit {
    @Input() stay;
    @Input() availability;
    @Input() availabilitySearch;
    @Input() roomCost = 0;
    @Input() promo;
    @Input() passportValidated;
    @Input() offers;
    @Input() hotel;
    @Input() room;

    @Input() dayToCheck;
    @Input() multiday = false;
    @Input() localAvailability;
    @Input() promotionsView = false;
    @Input() package = undefined;

    constructor() { }

    ngOnInit() {
        this.isRoomAvailable();
    }

    isRoomAvailable() {
        if (this.promotionsView) {
            for (let i = 0; i < this.availability[this.room.roomType].length; i++) {
                if (this.availability[this.room.roomType][i].d === this.dayToCheck) {
                    if (+this.availability[this.room.roomType][i].quantity > 0) {
                        this.roomCost = this.availability[this.room.roomType][i].costForStay;
                    }
                }
            }
        } else {
            for (let i = 0; i < this.availability[this.room.roomType].length; i++) {
                if (this.availability[this.room.roomType][i].d === this.dayToCheck) {
                    if (+this.availability[this.room.roomType][i].quantity > 0) {
                        this.roomCost = this.availability[this.room.roomType][i].costPerNight;
                    }
                }
            }
        }


    }


    viewRoom() { }

}
