<ng-container *ngIf="paymentData">
    <div class="text-center" *ngIf="!paymentData.result">
        <p>Select the card to take payment from</p>


        <ng-container *ngFor="let c of cards; let ci = index">
            <table class="table example-full-width data-listing">
                <tbody>
                    <tr>
                        <td width="25%">**** **** **** {{c.card.number}}</td>
                        <td width="25%">{{c.card.expiryMonth}}/{{c.card.expiryYear}}</td>
                        <td width="25%">{{c.card.holderName}}</td>
                        <td width="25%">
                            <a class="hover" (click)="selectCard(ci)">
                                <i *ngIf="cardSelected && cardSelected.recurringDetailReference === c.recurringDetailReference" class="fa fa-check-circle text-success" style="font-size: 25px;"></i>
                                <i *ngIf="cardSelected && cardSelected.recurringDetailReference !== c.recurringDetailReference" class="fa fa-check-circle text-light" style="font-size: 25px;"></i>
                            </a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-container>

        <div class="text-center"  *ngIf="!requestingPayment">
            <p *ngIf="cardSelected">Click the button below to take payment from card ending {{cardSelected.card.number}}</p>
            <!-- <p style="font-size: 11px; font-style: italic;">You will be able to amend the amount before committing</p> -->
            <button mat-raised-button color="warn" (click)="makePayment()">Make payment</button>

        </div>

        <!-- <div class="text-center" *ngIf="requestingPayment">
            <p *ngIf="cardSelected">Confirm payment value and then click 'Make Payment'</p>
            <div class="row">
                <div class="col-4 text-left">
                    <button mat-raised-button color="danger" (click)="requestingPayment = false; paymentResult = undefined">Cancel</button>
                </div>
                <div class="col-4">
                    <mat-form-field class="text-left" style="width: 100%">
                        <input matInput [(ngModel)]="paymentData.valueToDisplay" placeholder="Enter amount £">
                    </mat-form-field>

                </div>
                <div class="col-4 text-right">
                    <button mat-raised-button color="warn" (click)="makePayment()">Make Payment</button>
                </div>
            </div>

        </div> -->


        <div class="mt-3 text-center" *ngIf="processingPayment">
            <p class="mb-0">
                <i class="fa fa-spinner fa-spin"></i> Processing payment
            </p>
        </div>

        <div *ngIf="error" class="alert alert-warning mt-2">
            <p class="mb-0">{{error}}</p>
        </div>

        <div class="mt-3" *ngIf="paymentResult && requestingPayment">
            <div class="alert alert-success" *ngIf="paymentResult.resultCode === 'Authorised'">
                <p class="mb-0">Payment successful</p>
            </div>
            <div class="alert alert-danger" *ngIf="paymentResult.resultCode === 'Refused'">
                <p class="mb-0">Payment error - {{paymentResult.refusalReason}}</p>
            </div>
        </div>

        <div class="mt-3" *ngIf="paymentResult && statusCode">
            <div class="alert alert-danger">
                <p class="mb-0">Payment error - Please contact support</p>
            </div>
        </div>

    </div>

</ng-container>
