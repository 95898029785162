import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BasketService } from 'src/app/services/basket.service';
import { OverlayService } from 'src/app/services/overlay.service';
import { Store } from 'src/app/store';

@Component({
    selector: 'app-payment-link-complete',
    templateUrl: './payment-link-complete.component.html',
    styleUrls: ['./payment-link-complete.component.css']
})
export class PaymentLinkCompleteComponent implements OnInit {

    constructor(
        private store: Store,
        private router: Router,
        private basketService: BasketService,
        private overlayService: OverlayService,
    ) { }

    ngOnInit(): void {
    }

    bookAgain() {
        this.router.navigate(['/bookings/paymentlink'])
    }
}
