<div class="row">
    <div class="col-12 col-lg-6 offset-lg-3">

        <div class="complete-container">
            <i class="fa fa-check-circle text-success" style="font-size: 40px;"></i>
            <p class="section-subtitle" style="font-size: 28px;">Reservation successful</p>
            <p class="email" style="font-size: 24px;">A confirmation email has been sent to the booker with full details of their reservation.</p>

            <div class="mb-2 text-center">
                <a class="btn btn-primary mb-2" (click)="bookAgain()">
                    Return to start
                </a>

            </div>
        </div>

    </div>
</div>
