import { EventSkuEditComponent } from './events/event-sku-edit/event-sku-edit.component';
import { EventComponent } from './events/event/event.component';
import { EventsComponent } from './events/events/events.component';
import { VoucherEditComponent } from './voucher-edit/voucher-edit.component';
import { VoucherSelectComponent } from './voucher-select/voucher-select.component';
import { VoucherUsersComponent } from './voucher-users/voucher-users.component';
import { VouchersComponent } from './vouchers/vouchers.component';
import { ConfirmReportingExportComponent } from './reporting/confirm-reporting-export/confirm-reporting-export.component';
import { ReportingComponent } from './reporting/reporting.component';
import { GiftcardSearchComponent } from './giftcard-search/giftcard-search.component';
import { FeedbackBusinessDistroComponent } from './feedback/feedback-business-distro/feedback-business-distro.component';
import { FeedbackBusinessComponent } from './feedback/feedback-business/feedback-business.component';
import { ConfirmExportComponent } from './confirm-export/confirm-export.component';
import { FeedbackStatsQuarterComponent } from './feedback-stats-quarter/feedback-stats-quarter.component';
import { FeedbackStatsDailyComponent } from './feedback-stats-daily/feedback-stats-daily.component';
import { FeedbackStatsClusterComponent } from './feedback-stats-cluster/feedback-stats-cluster.component';
import { FeedbackStatsWeekComponent } from './feedback-stats-week/feedback-stats-week.component';
import { FeedbackTemplatesComponent } from './feedback/feedback-templates/feedback-templates.component';
import { FeedbackTemplateComponent } from './feedback/feedback-template/feedback-template.component';
import { FeedbackQuestionsComponent } from './feedback/feedback-questions/feedback-questions.component';
import { FeedbackQuestionComponent } from './feedback/feedback-question/feedback-question.component';
import { FeedbackInvitesComponent } from './feedback/feedback-invites/feedback-invites.component';
import { FeedbackAnswersComponent } from './feedback/feedback-answers/feedback-answers.component';
import { SiteDownComponent } from './site-down/site-down.component';
import { CustomerBookingsComponent } from './customer/customer-bookings/customer-bookings.component';
import { CustomerViewComponent } from './customer/customer-view/customer-view.component';
import { BrandBannerComponent } from './hotels/brand-banner/brand-banner.component';
import { FaqDeleteComponent } from './faq/faq-delete/faq-delete.component';
import { CmsContactComponent } from './cms-contact/cms-contact.component';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material.module';
import { LoginComponent } from './authentication/login/login.component';
import { LogoutComponent } from './authentication/logout/logout.component';
import { UserCreateComponent } from './user/user-create/user-create.component';
import { UsersComponent } from './user/users/users.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Store } from './store';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { GrowlerComponent } from './growler/growler.component';
import { QuillModule } from 'ngx-quill';
import { DonutComponent } from './charts/donut/donut.component';
import { UserEditComponent } from './user/user-edit/user-edit.component';
import { PaginationComponent } from './pagination/pagination.component';
import { ChartAssessmentScoresComponent } from './charts/chart-assessment-scores/chart-assessment-scores.component';
import { ChartPpSpendComponent } from './charts/chart-pp-spend/chart-pp-spend.component';
import { HotelsListComponent } from './hotels/hotels-list/hotels-list.component';
import { HotelsFilterComponent } from './hotels/hotels-filter/hotels-filter.component';
import { RoomsListComponent } from './rooms/rooms-list/rooms-list.component';
import { RoomsFilterComponent } from './rooms/rooms-filter/rooms-filter.component';
import { RoomTypeListComponent } from './room-types/room-type-list/room-type-list.component';
import { BookingsListComponent } from './bookings/bookings-list/bookings-list.component';
import { HotelsEditComponent } from './hotels/hotels-edit/hotels-edit.component';
import { HotelAddressFormComponent } from './hotels/hotel-address-form/hotel-address-form.component';
import { HotelOverviewComponent } from './hotels/hotel-overview/hotel-overview.component';
import { HotelCreateComponent } from './hotels/hotel-create/hotel-create.component';
import { RoomTypeAddComponent } from './room-types/room-type-add/room-type-add.component';
import { RoomTypeEditComponent } from './room-types/room-type-edit/room-type-edit.component';
import { RoomsEditComponent } from './rooms/rooms-edit/rooms-edit.component';
import { RoomOverviewComponent } from './rooms/room-overview/room-overview.component';
import { DebounceDirective } from './services/directives/debounce.directive';
import { ImagesComponent } from './images/images.component';
import { ImageDeleteComponent } from './images/image-delete/image-delete.component';
import { ImageUploadComponent } from './images/image-upload/image-upload.component';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { RoomFeaturesComponent } from './rooms/room-features/room-features.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DragulaModule } from 'ng2-dragula';
import { RoomTypeDeleteComponent } from './room-types/room-type-delete/room-type-delete.component';
import { BookingsFilterComponent } from './bookings/bookings-filter/bookings-filter.component';
import { BookingViewComponent } from './bookings/booking-view/booking-view.component';
import { CookieBannerComponent } from './cookie-banner/cookie-banner.component';
import { TestComponent } from './test/test.component';
import { CmsEditComponent } from './cms/cms-edit/cms-edit.component';
import { CmsListComponent } from './cms/cms-list/cms-list.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
import { HotelFeaturesListComponent } from './hotel-features/hotel-features-list/hotel-features-list.component';
import { HotelFeaturesEditComponent } from './hotel-features/hotel-features-edit/hotel-features-edit.component';
import { HotelFeaturesGroupComponent } from './hotel-features/hotel-features-group/hotel-features-group.component';
import { HotelRoomFeaturesComponent } from './hotel-features/hotel-room-features/hotel-room-features.component';
import { RoomSupplementComponent } from './rooms/room-supplement/room-supplement.component';
import { ProductDeleteComponent } from './product/product-delete/product-delete.component';
import { ProductEditComponent } from './product/product-edit/product-edit.component';
import { ProductGroupDeleteComponent } from './product/product-group-delete/product-group-delete.component';
import { ProductGroupEditComponent } from './product/product-group-edit/product-group-edit.component';
import { ProductGroupsComponent } from './product/product-groups/product-groups.component';
import { ProductsComponent } from './product/products/products.component';
import { ProductIndexComponent } from './product/product-index/product-index.component';
import { HotelFeaturesSortComponent } from './hotel-features/hotel-features-sort/hotel-features-sort.component';
import { PrebookComponent } from './hotels/prebook/prebook.component';
import { PrebookedComponent } from './prebooked/prebooked.component';
import { PackagesEditComponent } from './packages/packages-edit/packages-edit.component';
import { PackagesListComponent } from './packages/packages-list/packages-list.component';
import { PackagesFilterComponent } from './packages/packages-filter/packages-filter.component';
import { PackagesDeleteComponent } from './packages/packages-delete/packages-delete.component';
import { PendingChangesGuard } from './services/guards/pending-changes.guard';
import { RateGroupsListComponent } from './rate-groups/rate-groups-list/rate-groups-list.component';
import { RateGroupEditComponent } from './rate-groups/rate-group-edit/rate-group-edit.component';
import { CalendarComponent } from './calendar/calendar.component';
import { NumberpadPipe } from './services/pipes/numberPad';
import { PassportsComponent } from './passport/passports/passports.component';
import { PassportComponent } from './passport/passport/passport.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { UnconfirmedComponent } from './passport/unconfirmed/unconfirmed.component';
import { SignupsComponent } from './passport/signups/signups.component';
import { StaffComponent } from './passport/staff/staff.component';
import { SuppliersComponent } from './passport/suppliers/suppliers.component';
import { ReprintsComponent } from './passport/reprints/reprints.component';
import { PassportStatsComponent } from './passport/passport-stats/passport-stats.component';
import { FaqQuestionComponent } from './faq/faq-question/faq-question.component';
import { FaqQuestionsComponent } from './faq/faq-questions/faq-questions.component';
import { FaqTopicComponent } from './faq/faq-topic/faq-topic.component';
import { FaqTopicsComponent } from './faq/faq-topics/faq-topics.component';
import { EventBookingsComponent } from './events/event-bookings/event-bookings.component';
import { EventBookingComponent } from './events/event-booking/event-booking.component';
import { EventTypeComponent } from './events/event-type/event-type.component';
import { EventTypesComponent } from './events/event-types/event-types.component';
import { EventAttendeesComponent } from './events/event-attendees/event-attendees.component';
import { RrApiComponent } from './rr-api/rr-api.component';
import { ProductReportComponent } from './product-report/product-report.component';
import { GiftcardCreateComponent } from './giftcard/giftcard-create/giftcard-create.component';
import { GiftcardSelectComponent } from './giftcard/giftcard-select/giftcard-select.component';
import { GiftcardProductsComponent } from './giftcard/giftcard-products/giftcard-products.component';
import { RatePlansComponent } from './rate-plan/rate-plans/rate-plans.component';
import { EditRatePlanComponent } from './rate-plan/edit-rate-plan/edit-rate-plan.component';
import { FeedbackCommentComponent } from './feedback/feedback-comment/feedback-comment.component';
import { PasswordResetComponent } from './user/password-reset/password-reset.component';
import { PaymentLinkComponent } from './payment-link/payment-link.component';
import { PaymentLinkUserComponent } from './payment-link-user/payment-link-user.component';
import { UserSearchComponent } from './booking/user-search/user-search.component';
import { RoomSelectComponent } from './booking/room-select/room-select.component';
import { AvailabilityMapComponent } from './booking/availability-map/availability-map.component';
import { RoomCostMultidayComponent } from './booking/room-cost-multiday/room-cost-multiday.component';
import { RemovePencePipe } from './services/pipes/removePence.pipe';
import { PricePipe } from './services/pipes/price.pipe';
import { AddOnsComponent } from './booking/add-ons/add-ons.component';
import { BookingPaymentComponent } from './booking/booking-payment/booking-payment.component';
import { BookingEntryComponent } from './booking/booking-entry/booking-entry.component';
import { BookingCompleteComponent } from './booking/booking-complete/booking-complete.component';
import { BookingUserEditComponent } from './booking/booking-user-edit/booking-user-edit.component';
import { BookingNavbarComponent } from './booking/booking-navbar/booking-navbar.component';
import { AdyenComponent } from './adyen/adyen.component';
import { PaymentModalComponent } from './payment-modal/payment-modal.component';
import { AdyenPaylinkComponent } from './adyen-paylink/adyen-paylink.component';
import { AdyenStoredComponent } from './adyen-stored/adyen-stored.component';
import { ProcessingModalComponent } from './processing-modal/processing-modal.component';
import { AdyenRefundComponent } from './adyen-refund/adyen-refund.component';
import { PaymentLinkCompleteComponent } from './payment-link-complete/payment-link-complete.component';
import { BookingRateInfoComponent } from './booking/booking-rate-info/booking-rate-info.component';
import { BookingGiftcardEntryComponent } from './booking/booking-giftcard-entry/booking-giftcard-entry.component';

import { registerLocaleData } from '@angular/common';
import localeGb from '@angular/common/locales/en-GB';

import { MatDateFormats, MAT_DATE_FORMATS } from '@angular/material/core';

export const UK_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

registerLocaleData(localeGb);


// const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
//     // Change this to your upload POST address:
//      url: 'https://httpbin.org/post',
//      maxFilesize: 50,
//      acceptedFiles: 'image/*'
// };

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        LogoutComponent,
        UserCreateComponent,
        UserEditComponent,
        UsersComponent,
        DashboardComponent,
        GrowlerComponent,
        ChartAssessmentScoresComponent,
        ChartPpSpendComponent,
        DonutComponent,
        PaginationComponent,
        HotelsListComponent,
        HotelsFilterComponent,
        HotelsEditComponent,
        HotelsEditComponent,
        HotelCreateComponent,
        HotelAddressFormComponent,
        HotelOverviewComponent,
        RoomsListComponent,
        RoomsFilterComponent,
        RoomsEditComponent,
        RoomOverviewComponent,
        RoomTypeListComponent,
        RoomTypeAddComponent,
        RoomTypeEditComponent,
        RoomTypeDeleteComponent,
        RoomFeaturesComponent,
        BookingsListComponent,
        BookingsFilterComponent,
        BookingViewComponent,
        ImagesComponent,
        ImageDeleteComponent,
        ImageUploadComponent,
        DebounceDirective,
        CookieBannerComponent,
        TestComponent,
        CmsEditComponent,
        CmsListComponent,
        ImageCropperComponent,
        HotelFeaturesListComponent,
        HotelFeaturesEditComponent,
        HotelFeaturesGroupComponent,
        HotelRoomFeaturesComponent,
        RoomSupplementComponent,
        ProductDeleteComponent,
        ProductEditComponent,
        ProductGroupDeleteComponent,
        ProductGroupEditComponent,
        ProductGroupsComponent,
        ProductsComponent,
        ProductIndexComponent,
        HotelFeaturesSortComponent,
        TestimonialComponent,
        PrebookComponent,
        PrebookedComponent,
        PackagesEditComponent,
        PackagesListComponent,
        PackagesFilterComponent,
        PackagesDeleteComponent,
        RateGroupsListComponent,
        RateGroupEditComponent,
        CalendarComponent,
        NumberpadPipe,
        PassportsComponent,
        PassportComponent,
        ConfirmationComponent,
        UnconfirmedComponent,
        SignupsComponent,
        StaffComponent,
        SuppliersComponent,
        ReprintsComponent,
        PassportStatsComponent,
        FaqQuestionComponent,
        FaqQuestionsComponent,
        FaqTopicComponent,
        FaqTopicsComponent,
        CmsContactComponent,
        FaqDeleteComponent,
        BrandBannerComponent,
        CustomerViewComponent,
        CustomerBookingsComponent,
        SiteDownComponent,
        FeedbackAnswersComponent,
        FeedbackInvitesComponent,
        FeedbackQuestionComponent,
        FeedbackQuestionsComponent,
        FeedbackTemplateComponent,
        FeedbackTemplatesComponent,
        FeedbackStatsWeekComponent,
        FeedbackStatsClusterComponent,
        FeedbackStatsDailyComponent,
        FeedbackStatsQuarterComponent,
        FeedbackBusinessComponent,
        FeedbackBusinessDistroComponent,
        ConfirmExportComponent,
        GiftcardSearchComponent,
        ReportingComponent,
        ConfirmReportingExportComponent,
        VouchersComponent,
        VoucherUsersComponent,
        VoucherSelectComponent,
        VoucherEditComponent,
        EventsComponent,
        EventComponent,
        EventSkuEditComponent,
        EventBookingsComponent,
        EventBookingComponent,
        EventTypeComponent,
        EventTypesComponent,
        EventAttendeesComponent,
        RrApiComponent,
        ProductReportComponent,
        GiftcardCreateComponent,
        GiftcardSearchComponent,
        GiftcardSelectComponent,
        GiftcardProductsComponent,
        RatePlansComponent,
        EditRatePlanComponent,
        FeedbackCommentComponent,
        PasswordResetComponent,
        PaymentLinkComponent,
        PaymentLinkUserComponent,
        PaymentLinkCompleteComponent,
        UserSearchComponent,
        RoomSelectComponent,
        AvailabilityMapComponent,
        RoomCostMultidayComponent,
        RoomSelectComponent,
        AddOnsComponent,
        BookingPaymentComponent,
        BookingEntryComponent,
        BookingCompleteComponent,
        BookingUserEditComponent,
        BookingNavbarComponent,
        AdyenComponent,
        AdyenPaylinkComponent,
        AdyenStoredComponent,
        AdyenRefundComponent,
        PaymentModalComponent,
        BookingRateInfoComponent,
        PricePipe,
        RemovePencePipe,
        ProcessingModalComponent,
        BookingGiftcardEntryComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        QuillModule,
        DropzoneModule,
        DragDropModule,
        DragulaModule.forRoot(),
        ImageCropperModule,
        NgxBarcodeModule,
    ],
    providers: [Store, PendingChangesGuard, { provide: LOCALE_ID, useValue: 'en-GB' }, { provide: MAT_DATE_FORMATS, useValue: UK_DATE_FORMATS }],
    bootstrap: [AppComponent]
})
export class AppModule { }
