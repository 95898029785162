<!--<div class="alert alert-danger" *ngIf="growlerData.errors.length">-->
<!--    <div class="messages messages-error">-->
<!--        <button mat-button (click)="close()">-->
<!--            <mat-icon class="close">clear</mat-icon>-->
<!--        </button>-->
<!--        -->
<!--        <div class="messages-container">-->
<!--            <ul class="alertUL">-->
<!--                <li *ngFor="let message of growlerData.errors">{{message}}</li>-->
<!--            </ul>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->
<!--<div class="alert alert-success" *ngIf="growlerData.notifications.length">-->
<!--    <div class="messages messages-success">-->
<!--        <button mat-button (click)="close()">-->
<!--            <mat-icon class="close">clear</mat-icon>-->
<!--        </button>-->
<!--        -->
<!--        <div class="messages-container">-->
<!--            <ul class="alertUL">-->
<!--                <li *ngFor="let message of growlerData.notifications">{{message}}</li>-->
<!--            </ul>-->
<!--        </div>-->
<!--    </div>-->
<!--</div>-->


<div class="sweet-overlay" tabindex="-1" style="opacity: 1.08; display: block;"></div>
<div
		class="sweet-alert showSweetAlert visible"
		data-custom-class=""
		data-has-cancel-button="false"
		data-has-confirm-button="true"
		data-allow-outside-click="false"
		data-has-done-function="false"
		data-animation="pop"
		data-timer="null" style="display: block; margin-top: -171px;">

	<!-- <div class="sa-icon sa-error" *ngIf="growlerData.errors.length">
      <span class="sa-x-mark">
        <span class="sa-line sa-left"></span>
        <span class="sa-line sa-right"></span>
      </span>
	</div>

	<div class="sa-icon sa-success animate" style="display: block;" *ngIf="growlerData.notifications.length">
		<span class="sa-line sa-tip animateSuccessTip"></span>
		<span class="sa-line sa-long animateSuccessLong"></span>

		<div class="sa-placeholder"></div>
		<div class="sa-fix"></div>
	</div> -->

	<ng-container *ngIf="growlerData.notifications.length">
		<h2>Success!</h2>
		<p style="display: block;"  *ngFor="let message of growlerData.notifications">{{message}}</p>
	</ng-container>

	<ng-container *ngIf="growlerData.errors.length">
		<h2>Sorry - something isn't quite right!</h2>
		<p style="display: block;" *ngFor="let message of growlerData.errors">{{message}}</p>
	</ng-container>

	<div class="sa-button-container">
		<button class="cancel" tabindex="2" style="display: none; box-shadow: none;">Cancel</button>
		<button class="confirm" tabindex="1" (click)="close()"
				style="display: inline-block;
				background-color: rgb(174, 222, 244);
				box-shadow: rgba(174, 222, 244, 0.8) 0px 0px 2px,
				rgba(0, 0, 0, 0.05) 0px 0px 0px 1px inset;">
			OK
		</button>
	</div>
</div>

