
<div
    class="av-cell available fs-2 h-100 w-100 hover"
    *ngIf="roomCost > 0 && (+stay.adults <= +room.maxAdults && +stay.children <= +room.maxChildren)"
    (click)="viewRoom()">
    {{roomCost | removePence}}
</div>
<div
    class="av-cell fs-2 h-100 w-100" style="background-color: orange; color: white; font-size: 10px"
    *ngIf="roomCost > 0 && (+stay.adults > +room.maxAdults)">
    !Max adults
</div>
<ng-container>

</ng-container>
<div
    class="av-cell fs-2 h-100 w-100" style="background-color: orange; color: white; font-size: 10px"
    *ngIf="roomCost > 0 && (+stay.children > +room.maxChildren)">
    !Max children
</div>

<div
    class="av-cell"
    *ngIf="roomCost === 0">

</div>

