import { Store } from './../../store';
import { Router } from '@angular/router';
import { GrowlerService } from './../../services/growler.service';
import { OverlayService } from './../../services/overlay.service';
import { Component, OnInit } from '@angular/core';
import { debounce } from 'lodash';
import { RatePlanService } from 'src/app/services/rate-plan.service';
import { ToolsService } from 'src/app/services/tools.service';

@Component({
    selector: 'app-rate-plans',
    templateUrl: './rate-plans.component.html',
    styleUrls: ['./rate-plans.component.css']
})
export class RatePlansComponent implements OnInit {

    hotels$ = this.store.select<any>('hotels');
    data = [];
    dataToDelete;
    deleteConfirmation = {
        title: 'Confirm delete',
        detail: 'Are you sure you want to delete this rate plan?'
    }

    loading = false;

    startDate;
    endDate;
    bookingStartDate;
    bookingEndDate;

    yesNo = [{ id: 0, name: 'Please Select' }, { id: 1, name: 'Yes' }, { id: 2, name: 'No' }]
    params = {
        searchText: '',
        hotel: '',
        bookingStartDate: undefined,
        bookingEndDate: undefined,
        startDate: undefined,
        endDate: undefined,
        status: -1,
        isDerivative: -1,
        limit: 50,
        limits: [50, 100, 250],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: "rate_plans.name",
        sorts: [
            { ID: "rate_plans.name ASC", name: "Name A-Z" },
            { ID: "rate_plans.name DESC", name: "Name Z-A" },
            { ID: "rate_plans.createdAt DESC", name: "Recent First" },
            { ID: "rate_plans.createdAt ASC", name: "Recent Last" },
        ],
    };

    constructor(
        private store: Store,
        private overlayService: OverlayService,
        private growlerService: GrowlerService,
        private router: Router,
        private ratePlanService: RatePlanService,
        private toolsService: ToolsService,
    ) {
        this.filterSearch = debounce(this.filterSearch, 450);
    }

    ngOnInit() {
        this.filterSearch();
    }

    setSort(v) {
        this.params.sort = v;
        this.search();
    }

    setParam(name, value) {
        this.params[name] = '';
        this.search();
    }

    filterSearch() {
        this.params.page = 0;
        this.search();
    }

    search() {
        this.loading = true;

        if (this.startDate) {
            this.params.startDate = this.toolsService.buildDateString(this.startDate)
        }
        if (this.endDate) {
            this.params.endDate = this.toolsService.buildDateString(this.endDate)
        }
        if (this.bookingStartDate) {
            this.params.bookingStartDate = this.toolsService.buildDateString(this.bookingStartDate);
        }
        if (this.bookingEndDate) {
            this.params.bookingEndDate = this.toolsService.buildDateString(this.bookingEndDate);
        }


        this.ratePlanService.search(this.params).subscribe(data => {
            console.log(data.data)
            this.data = data.data;

            this.params.pages = Math.ceil(data.totalRecords / this.params.limit);
            this.params.totalRecords = data.totalRecords;
            this.params.pageArray = [];
            for (let i = 0; i < this.params.pages; i++) {
                this.params.pageArray.push(i);
            }
            window.scroll(0, 0);
            this.loading = false;
        });
    }

    resetParams() {
        this.startDate = undefined;
        this.endDate = undefined;
        this.bookingStartDate = undefined;
        this.bookingEndDate = undefined;


        this.params = {
            searchText: '',
            hotel: '',
            bookingStartDate: undefined,
            bookingEndDate: undefined,
            startDate: undefined,
            endDate: undefined,
            status: -1,
            isDerivative: -1,
            limit: 50,
            limits: [50, 100, 250],
            page: 0,
            pages: 0,
            pageArray: [],
            totalRecords: 0,
            sort: "rate_plans.name",
            sorts: [
                { ID: "rate_plans.name ASC", name: "Name A-Z" },
                { ID: "rate_plans.name DESC", name: "Name Z-A" },
                { ID: "rate_plans.createdAt DESC", name: "Recent First" },
                { ID: "rate_plans.createdAt ASC", name: "Recent Last" },
            ],
        }

        this.search();
    }


    setStatus(data) {
    }

    isOdd(num) {
        if (num % 2 === 1) {
            return false;
        } else {
            return true;
        }
    }

    delete(data) {
        this.dataToDelete = data;
    }
    deleteAction(e) {

        if (e.action) {
            this.ratePlanService.delete(this.dataToDelete).subscribe(data => {
                this.dataToDelete = undefined;
                this.search();
            })
        } else {
            this.dataToDelete = undefined;
        }
    }
    toggleStatus(ratePlan) {
        if (!ratePlan.status) {
            ratePlan.status = 1
        } else {
            ratePlan.status = 0;
        }
        this.ratePlanService.toggleStatus(ratePlan).subscribe(data => {
            this.filterSearch();
        })
    }
    create() {
        this.router.navigate(['/rateplans', 'create'])
    }
    update(data) {
        this.router.navigate(['/rateplans', data.id])
    }
}
