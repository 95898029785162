import { Injectable } from '@angular/core';
import { Store } from '../store';

@Injectable({
    providedIn: 'root'
})
export class OverlayService {

    constructor(
        private store: Store
    ) { }

    setProcessing(v) {
        this.store.set('processing', v)
    }
    open(v) {
        const o = this.store.selectForLocal('overlays');
        o[v] = true;
        this.store.set('overlays', o);
    }

    close(v) {
        const o = this.store.selectForLocal('overlays');
        o[v] = false;
        this.store.set('overlays', o);
        this.store.set('asideOpen', false);
    }
    closeAll() {
        const o = this.store.selectForLocal('overlays');
        const objKeys = Object.keys(o);
        console.log(objKeys);
        for (let i = 0; i < objKeys.length; i++) {
            o[objKeys[i]] = false;
        }
        this.store.set('overlays', o);
        this.store.set('asideOpen', false);
    }

}
