<div class="example-container" [class.example-is-mobile]="mobileQuery.matches">
    <mat-toolbar color="primary" class="example-toolbar" *ngIf="(user$ | async)">
        <button mat-icon-button (click)="toggleMenu()" *ngIf="(user$ | async)">
            <mat-icon>menu</mat-icon>
        </button>
        <span *ngIf="(user$ | async)?.permissions['headerBP'] && (area$ | async) === 'bookings'">Booking Portal - Admin</span>
        <span *ngIf="(user$ | async)?.permissions['headerFeedback'] && (area$ | async) === 'feedback'">Feedback - Admin</span>
        <span *ngIf="(user$ | async)?.permissions['headerEvents'] && (area$ | async) === 'events'">Events - Admin</span>
        <span *ngIf="(user$ | async)?.permissions['headerGF'] && (area$ | async) === 'giftcards'">Giftcards - Admin</span>
        <span *ngIf="(user$ | async)?.permissions['headerMarketing'] && (area$ | async) === 'marketing'">Marketing</span>
        <span *ngIf="(user$ | async)?.permissions['headerSettings'] && (area$ | async) === 'passport'">Norfolk Passport - Admin</span>

        <div style="text-align: right; width: 100%;" [style.display]="mobileQuery.matches ? 'none' : 'block'">
            <span class="example-spacer"></span>
            <button mat-raised-button (click)="setArea('bookings', false)" *ngIf="(user$ | async)?.permissions['headerBP']">
                Booking Portal
            </button>
            <button mat-raised-button class="ml-3" (click)="setArea('events', false)" *ngIf="(user$ | async)?.permissions['headerEvents']">
                Events
            </button>
            <button mat-raised-button class="ml-3" (click)="setArea('feedback', false)" *ngIf="(user$ | async)?.permissions['headerFeedback']">
                Feedback
            </button>
            <button mat-raised-button class="ml-3" (click)="setArea('giftcards', false)" *ngIf="(user$ | async)?.permissions['headerGF']">
                Gift Cards
            </button>
            <button mat-raised-button class="ml-3" (click)="setArea('marketing', false)" *ngIf="(user$ | async)?.permissions['headerMarketing']">
                Marketing
            </button>
            <button mat-raised-button class="ml-3" (click)="setArea('passport', false)" *ngIf="(user$ | async)?.permissions['headerPassport']">
                Passport
            </button>
            <button mat-raised-button class="ml-3" (click)="setArea('settings', false)" *ngIf="(user$ | async)?.permissions['headerSettings']">
                Settings
            </button>

        </div>

        <!-- <button mat-icon-button (click)="toggleMenu()" *ngIf="(user$ | async)" [style.display]="mobileQuery.matches ? 'block' : 'none'">
            <mat-icon>menu</mat-icon>
        </button> -->

    </mat-toolbar>

    <mat-sidenav-container
        class="example-sidenav-container"
        [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
        <mat-sidenav style="z-index: 1;"
            #snav
            [opened]="(menuOpen$ | async) && (user$ | async)"
            [mode]="mobileQuery.matches ? 'over' : 'side'"
            [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
            <mat-nav-list>
                <ng-container *ngIf="(user$ | async)?.permissions">
                    <ng-container *ngFor="let option of (menu$ | async)">
                        <a mat-list-item
                            (click)="navigate(option)"
                            *ngIf="(user$ | async)?.permissions[option.permissionGroup] && (area$ | async) === option.area">
                            <mat-icon>{{option.icon}}</mat-icon> &nbsp; {{option.name}}
                        </a>
                    </ng-container>
                    <mat-divider></mat-divider>

                </ng-container>


                <div [style.display]="mobileQuery.matches ? 'block' : 'none'">
                    <a mat-list-item (click)="setArea('bookings', true)" *ngIf="(user$ | async)?.permissions['headerBP']">
                        Booking Portal
                    </a>
                    <a mat-list-item (click)="setArea('events', true)" *ngIf="(user$ | async)?.permissions['headerEvents']">
                        Events
                    </a>
                    <a mat-list-item (click)="setArea('feedback', true)" *ngIf="(user$ | async)?.permissions['headerFeedback']">
                        Feedback
                    </a>
                    <a mat-list-item (click)="setArea('giftcards', true)" *ngIf="(user$ | async)?.permissions['headerGF']">
                        Gift Cards
                    </a>
                    <a mat-list-item (click)="setArea('marketing', true)" *ngIf="(user$ | async)?.permissions['headerMarketing']">
                        Marketing
                    </a>
                    <a mat-list-item (click)="setArea('passport', true)" *ngIf="(user$ | async)?.permissions['headerPassport']">
                        Passport
                    </a>
                    <a mat-list-item (click)="setArea('settings', true)" *ngIf="(user$ | async)?.permissions['headerSettings']">
                        Settings
                    </a>

                </div>

                <mat-divider></mat-divider>
                <a mat-list-item (click)="logoutConfirmation()">
                    <mat-icon>exit_to_app</mat-icon>
                    Sign Out
                </a>
            </mat-nav-list>
        </mat-sidenav>

        <mat-sidenav-content>
            <div class="container-fluid">
                <router-outlet></router-outlet>
            </div>
        </mat-sidenav-content>


        <mat-sidenav
            #enav
            style="width: 450px"
            [opened]="(asideOpen$ | async)"
            [mode]="'over'"
            [position]="'end'"
            (closedStart)="closeAside()"
            [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
            <app-hotel-features-sort *ngIf="(overlays$ | async).featureGroupsSort"></app-hotel-features-sort>
            <app-product-group-edit *ngIf="(overlays$ | async).productGroup"></app-product-group-edit>
            <app-product-group-delete *ngIf="(overlays$ | async).productGroupDelete"></app-product-group-delete>
            <app-faq-question *ngIf="(overlays$ | async).faqQuestion"></app-faq-question>
            <app-faq-delete *ngIf="(overlays$ | async).faqDelete"></app-faq-delete>
			<app-packages-delete *ngIf="(overlays$ | async).packageDelete" (dataDeleted)="packageDeleted($event)"></app-packages-delete>
            <app-product-edit *ngIf="(overlays$ | async).product"></app-product-edit>
            <app-product-delete *ngIf="(overlays$ | async).productDelete"></app-product-delete>
            <app-hotel-features-group *ngIf="(overlays$ | async).featureGroup"></app-hotel-features-group>
            <app-room-features [room]="(room$ | async)" *ngIf="(overlays$ | async).roomFeatures"></app-room-features>
            <app-room-type-add *ngIf="(overlays$ | async).roomTypeAdd"></app-room-type-add>
            <app-room-type-edit *ngIf="(overlays$ | async).roomTypeEdit" [roomType]="(roomType$ | async)"></app-room-type-edit>
            <app-user-create *ngIf="(overlays$ | async).userCreate"></app-user-create>
            <app-user-edit *ngIf="(overlays$ | async).userUpdate" [user]="(userToEdit$ | async)"></app-user-edit>
            <app-password-reset *ngIf="(overlays$ | async).passwordReset" [user]="(userToEdit$ | async)"></app-password-reset>

            <app-feedback-template *ngIf="(overlays$ | async).feedbackTemplate"></app-feedback-template>


            <app-voucher-edit *ngIf="(overlays$ | async).voucherEdit"></app-voucher-edit>
        </mat-sidenav>


    </mat-sidenav-container>
    <app-room-type-delete *ngIf="(overlays$ | async).roomTypeDelete" [modalData]="(roomType$ | async)" (result)="refreshRoomTypes($event)"></app-room-type-delete>

    <app-growler [growlerData]="(growler$ | async)" *ngIf="(growler$ | async).errors.length || (growler$ | async).notifications.length"></app-growler>

    <app-image-cropper [image]="(cropImage$ | async)" *ngIf="(overlays$ | async).imageCropper" (result)="imageCropped($event)"></app-image-cropper>
    <app-processing-modal *ngIf="(processing$ | async).open" [title]="(processing$ | async).title"></app-processing-modal>

</div>




