import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BasketService } from 'src/app/services/basket.service';
import { OverlayService } from 'src/app/services/overlay.service';
import { Store } from 'src/app/store';

@Component({
    selector: 'app-booking-complete',
    templateUrl: './booking-complete.component.html',
    styleUrls: ['./booking-complete.component.css']
})
export class BookingCompleteComponent implements OnInit {
    hotel = this.store.selectForLocal('hotel')

    constructor(
        private store: Store,
        private router: Router,
        private basketService: BasketService,
        private overlayService: OverlayService,
    ) { }

    ngOnInit(): void {
        const basket = this.basketService.create();

        this.store.set('basket', basket);
        this.basketService.saveBasket(basket)
    }

    bookAgain() {
        this.router.navigate(['/bookings/create'])
    }


}
