import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-processing-modal',
    templateUrl: './processing-modal.component.html',
    styleUrls: ['./processing-modal.component.css']
})
export class ProcessingModalComponent implements OnInit {
    @Input() title = 'Processing....'
    constructor() { }

    ngOnInit(): void {
    }

}
