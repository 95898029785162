import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BasketService } from 'src/app/services/basket.service';
import { UserService } from 'src/app/services/user.service';
import { Store } from 'src/app/store';

@Component({
    selector: 'app-user-search',
    templateUrl: './user-search.component.html',
    styleUrls: ['./user-search.component.css']
})
export class UserSearchComponent implements OnInit {
    @Input() layout = 'booking';

    creatingCustomer = false;

    selectedUserId = 'new';
    searching = false;

    data = [];
    params = {
        quickSearch: true,
        type: "all",
        hasNotes: 0,
        feedback: 0,
        unconfirmed: false,
        printed: true,
        staffGroup: 0,
        isSupplier: false,
        reprint: false,
        signUp: false,
        searchText: "",
        limit: 10,
        limits: [10, 20, 50],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: "users.eposNowId DESC",
        sorts: [
            { ID: "users.eposNowId DESC", name: "ID First" },
            { ID: "users.eposNowId ASC", name: "ID Last" },
            { ID: "users.createdAt DESC", name: "Recent First" },
            { ID: "users.createdAt ASC", name: "Recent Last" },
            { ID: "users.firstname ASC", name: "Name A-Z" },
            { ID: "users.firstname DESC", name: "Name Z-A" },
            { ID: "users.feedback_qt DESC", name: "By feedback score" },
        ],
    }

    constructor(
        private store: Store,
        private userService: UserService,
        private router: Router,
        private basketService: BasketService,
    ) {
    }

    ngOnInit() {


    }

    search() {
        this.searching = true;
        this.userService.searchUsers(this.params).subscribe(data => {
            console.log(data.data);
            this.searching = false;
            this.data = data.data;
            this.params.pages = Math.ceil(
                data.totalRecords / this.params.limit
            );
            this.params.totalRecords = data.totalRecords;
            this.params.pageArray = [];
            for (let i = 0; i < this.params.pages; i++) {
                this.params.pageArray.push(i);
            }

        })
    }

    edit(r) { }

    toggleCreating() {
        if (!this.creatingCustomer) {
            this.creatingCustomer = true;
        } else {
            this.creatingCustomer = false;
        }
    }

    select(user) {
        if (this.layout === 'booking') {
            const basket = this.basketService.getBasket()
            console.log(user);
            basket.userId = user.id;
            basket.user = user;
            this.store.set('basket', basket);
            this.basketService.saveBasket(basket);

            this.router.navigate(['/bookings/create/availability'])

        } else {
            const paymentLinkData = {
                user,
                description: '',
                reference: '',
                amountToPay: 0,
                payUrlId: '',
                payUrl: '',
                payUrlExpires: undefined
            }
            this.store.set('paymentLinkData', paymentLinkData);
            console.log(paymentLinkData)
            this.router.navigate(['/bookings/paymentlink/create'])

        }
    }
}
