<ng-container *ngIf="paymentData">
    <div class="text-center" *ngIf="!paymentData.result">
        <p *ngIf="isBooking">Click the button below to send a payment link to complete the booking.</p>
        <p *ngIf="!isBooking">Click the button below to send a payment link</p>


        <button class="btn btn-sm btn-primary" (click)="createPaymentLink(1234)">
            Send Payment Link for {{paymentData.valueToDisplay | currency: 'GBP'}}
            <i class="fa fa-spinner fa-spin ml-2" *ngIf="processing"></i>
        </button>
        <p style="font-size: 10px;" *ngIf="isBooking">*Payment links will be active for 2 hours. Booking will be cancelled if payment is not made within this time.</p>
        <p style="font-size: 10px;" *ngIf="!isBooking">*Payment links will be active for 2 hours.</p>


        <p class="mt-3 hover" *ngIf="!emailEdit" (click)="toggleEmailEdit()">
            An email with the payment link will be sent to
            <span *ngIf="!paymentData.shopper.emailAlt">{{paymentData.shopper.email}}</span>
            <span *ngIf="paymentData.shopper.emailAlt">{{paymentData.shopper.emailAlt}}</span>
                <i class="fa fa-edit ml-2"></i>
        </p>


        <div class="mt-3" *ngIf="emailEdit">
            <div class="form-group row mt-4">
                <label class="col-sm-5 col-form-label text-right">Edit email address</label>
                <div class="col-sm-7">
                    <input type="text" class="form-control form-control-sm" [(ngModel)]="paymentData.shopper.emailAlt" />
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-6">
                    <!-- <button class="btn btn-sm btn-warning" (click)="toggleEmailEdit()">Cancel</button> -->
                </div>
                <div class="col-6 text-right">
                    <button class="btn btn-sm btn-warning mr-2" (click)="toggleEmailEdit()">Cancel</button>
                    <button class="btn btn-sm btn-primary" (click)="saveAltEmail()">Save</button>
                </div>
            </div>
        </div>

    </div>
    <div class="text-center" *ngIf="paymentData.result && paymentData.result.paymentLinkUrl">
        <p *ngIf="!paymentData.shopper.emailAlt">The payment link has been created and sent to {{paymentData.shopper.email}}</p>
        <p *ngIf="paymentData.shopper.emailAlt">The payment link has been created and sent to {{paymentData.shopper.emailAlt}}</p>
        <a [href]="paymentLinkUrl" target="_blank">{{paymentData.result.paymentLinkUrl}}</a>
    </div>

</ng-container>
