import { Component, OnInit, Input } from '@angular/core';
import { OverlayService } from 'src/app/services/overlay.service';
import { Store } from 'src/app/store';

@Component({
    selector: 'app-booking-rate-info',
    templateUrl: './booking-rate-info.component.html',
    styleUrls: ['./booking-rate-info.component.css']
})
export class BookingRateInfoComponent implements OnInit {
    @Input() ratePlan;

    constructor(
        private overlayService: OverlayService,
        private store: Store,
    ) { }

    ngOnInit() {
    }
    close() {
        this.overlayService.close('ratePlanInfo');
        this.store.set('infoPlan', undefined);
    }

}
