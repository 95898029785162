<div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-body" style="padding: 60px;">
                <div class="text-center">

                    <i class="fa fa-spinner fa-spin" style="font-size: 40px;"></i>
                    <p class="mb-0 mt-4" style="font-size: 22px;">{{title}}</p>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-backdrop fade show modal-slide-show"></div>
