import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
    }),
};

@Injectable({
    providedIn: 'root',
})
export class AdyenService {
    constructor(private http: HttpClient) { }

    getUserCards(params) {
        const dataToSend = {
            params
        }
        return this.http.post(environment.apiV2Path + 'adyen/cardsOnFile/', dataToSend).pipe(map((data: any) => {
            return data;
        }));
    }

    chargeStoredCard(paymentData) {
        const dataToSend = {
            paymentData
        }
        return this.http.post(environment.apiV2Path + 'adyen/charge/stored', dataToSend).pipe(map((data: any) => {
            return data;
        }));

    }

    createBookingLink(linkData) {
        return this.http.post(environment.apiV2Path + 'adyen/bookinglink/create', linkData).pipe(map((data: any) => {
            return data;
        }));
    }

    createPaymentLink(data) {
        const dataToSend = {
            data
        }
        return this.http.post(environment.apiV2Path + 'adyen/link/create', dataToSend).pipe(map((data: any) => {
            return data;
        }));

    }

    refund(refundData) {
        const dataToSend = {
            refundData
        }
        return this.http.post(environment.apiV2Path + 'adyen/refund', dataToSend).pipe(map((data: any) => {
            return data;
        }));

    }


    sessions(lineItems, shopper, merchant, amountToPay, metadata, isEvents): Observable<any> {

        const dataToSend = {
            lineItems,
            merchant: merchant,
            amountToPay: amountToPay,
            shopper,
            metadata: metadata,

        }



        return this.http.post(environment.apiV2Path + 'adyen/sessions/moto', dataToSend, httpOptions);
    }
}
