import { Component, OnInit } from '@angular/core';
import { OverlayService } from '../../services/overlay.service';
import { GrowlerService } from '../../services/growler.service';
import { Store } from '../../store';
import { UserService } from '../../services/user.service';

@Component({
    selector: 'app-user-create',
    templateUrl: './user-create.component.html',
    styleUrls: ['./user-create.component.css']
})
export class UserCreateComponent implements OnInit {

    user = {
        firstname: '',
        surname: '',
        email: '',
        password: '',
        created: new Date(),
        status: 1,
        permissionGroup: [],
    };

    permissions = [];

    constructor(
        private usersService: UserService,
        private store: Store,
        private overlayService: OverlayService,
        private growlerService: GrowlerService,
    ) { }

    ngOnInit() {
        this.loadPermissions();
    }

    loadPermissions() {
        this.usersService.loadPermissions().subscribe(data => {
            console.log(data)
            this.user.permissionGroup = data;
        });
    }

    checkSubPerms(e, p) {
        console.log(e)
        console.log(p)

        if (!p.allow) {
            for (let i = 0; i < p.permissions.length; i++) {
                p.permissions[i].allow = false;
            }
            return;
        }

        for (let i = 0; i < p.permissions.length; i++) {
            p.permissions[i].allow = true;
        }
        return;
    }

    checkParentPerms(e, p, pi) {
        let hasNonSelected = true;
        for (let i = 0; i < p.permissions.length; i++) {

            if (p.permissions[i].allow) {
                hasNonSelected = false;
            }
        }
        if (hasNonSelected) {
            p.allow = false;
        } else {
            p.allow = true;
        }
    }


    submit() {
        this.usersService.create(this.user).subscribe(data => {
            if (data.status === 500) {
                this.growlerService.error(data.errors);
            } else {
                this.growlerService.notification(['User Created']);
                this.close();
            }

        });
    }

    close() {
        this.overlayService.closeAll();
    }
}
