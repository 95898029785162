import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { Store } from '../store';
import { GrowlerService } from './growler.service';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class BookingService {

    env = environment;

    defaultParams = {
        searchTerm: '',
        name: '',
        hotelID: '',
        upgraded: '',
        hasPromo: '',
        hasExtras: '',
        county: '',
        startDate: undefined,
        endDate: undefined,
        limit: 20,
        limits: [20, 50, 75, 100],
        page: 0,
        pages: 0,
        pageArray: [],
        totalRecords: 0,
        sort: 'booking.created DESC',
        sorts: [
            { ID: 'booking.created DESC', name: 'Recent First' },
            { ID: 'booking.created ASC', name: 'Recent Last' },
            { ID: 'hotel.name ASC', name: 'Hotel Name A-Z' },
            { ID: 'hotel.name DESC', name: 'Hotel Name Z-A' },
            { ID: 'county.name DESC', name: 'County A-Z' },
            { ID: 'county.name ASC', name: 'County Z-A' },
        ],
    };

    constructor(
        private http: HttpClient,
        private apiService: ApiService,
        private store: Store,
        private growlerService: GrowlerService,
    ) { }

    updateParams(params) {
        this.store.set('bookingParams', params);
        this.search();
    }
    resetParams() {
        this.store.set('bookingParams', JSON.parse(JSON.stringify(this.defaultParams)));
        console.log(JSON.parse(JSON.stringify(this.defaultParams)))
        this.search();
    }



    search() {
        const dataToSend = {
            user: this.store.selectForLocal('user'),
            params: this.store.selectForLocal('bookingParams')
        };
        return this.http.post(this.apiService.path() + 'bookings/search', dataToSend).subscribe((data: any) => {
            this.store.set('bookings', data.data);
            const p = this.store.selectForLocal('bookingParams');
            p.pages = Math.ceil(data.params.totalRecords / p.limit);
            p.totalRecords = data.params.totalRecords;
            p.pageArray = [];
            for (let i = 0; i < p.pages; i++) {
                p.pageArray.push(i);
            }
            this.store.set('bookingParams', p);

            this.store.set('rrBacklog', data.rrBacklog)
        });
    }

    markAsChecked(bookingId) {
        return this.http.get(environment.apiV2Path + 'bookings/checked/' + bookingId).pipe(map((data: any) => {
            return data;
        }));
    }

    getSpaOrder(trybeOrderId) {
        return this.http.get(environment.apiV2Path + 'trybe/order/' + trybeOrderId).pipe(map((data: any) => {
            return data;
        }));
    }

    searchGiftCards(params) {
        const dataToSend = {
            params,
        };
        return this.http.post(this.env.apiV2Path + 'bookings/giftcard/transactions', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                })
            );
    }

    searchExtras(params) {
        const dataToSend = {
            params,
        };
        return this.http.post(this.env.apiV2Path + 'bookings/extras/transactions', dataToSend)
            .pipe(
                map((data: any) => {
                    return data.data;
                })
            );
    }


    exportBookings() {
        const dataToSend = {
            user: this.store.selectForLocal('user'),
            params: this.store.selectForLocal('bookingParams')
        };

        return this.http
            .post(
                this.apiService.path() + 'bookings/export',
                dataToSend,
                this.apiService.getHttpOptions()
            )
            .pipe(
                map((data: any) => {
                    return data;
                })
            );
    }



    byCustomer(customerId) {
        return this.http.get(this.apiService.path() + 'bookings/customer/' + customerId).pipe(map((data: any) => {
            return data.data;
        }));
    }



    setBooking(booking) {
        const data = JSON.parse(JSON.stringify(this.store.selectForLocal('bookings')));
        for (let i = 0; i < data.length; i++) {
            if (data[i].id === booking.id) {
                data[i] = booking;
            }
        }
        this.store.set('booking', booking);
        this.store.set('bookings', data);
    }

    get(id) {
        const dataToSend = {
            user: this.store.selectForLocal('user'),
            params: this.store.selectForLocal('bookingParams'),
            id: id
        };
        return this.http.post(this.apiService.path() + 'bookings/id', dataToSend).pipe(map((data: any) => {
            return data.data;
        }));
    }
    reconcile(booking) {
        return this.http.get(this.apiService.path() + 'bookings/reconcile/' + booking.id).subscribe((data: any) => {
            return data.data;
        });
    }

    resendGiftCardEmail(id) {
        return this.http.get(this.env.apiV2Path + 'bookings/giftcard/resend/' + id).pipe(map((data: any) => {
            return data.data;
        }));
    }


    giftCardCheckPoints(giftCard) {
        return this.http.post(environment.apiV2Path + 'bookings/giftcard/check', giftCard).pipe(map((data: any) => {
            return data;
        }));
    }


    giftCardPurchase(basket) {

        const dataToSend = {
            basket
        }
        return this.http.post(environment.apiV2Path + 'bookings/giftcard/purchase', dataToSend).pipe(map((data: any) => {
            return data;
        }));

    }

    cancelBooking(id) {
        return this.http.delete(this.env.apiV2Path + 'bookings/cancel/' + id).pipe(map((data: any) => {
            return data.data;
        }));
    }

    createMultiCheckout() {
        const basket = this.store.selectForLocal('basket');

        const dataToSend = {
            basket
        }
        return this.http.post(environment.apiV2Path + 'bookings/basket/process', dataToSend).pipe(map((data: any) => {
            return data;
        }));
    }

}
