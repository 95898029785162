<div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header" *ngIf="ratePlan">
                <h5 class="modal-title">{{ratePlan.name}}</h5>
                <a class="close hover" (click)="close()">
                    <span aria-hidden="true">&times;</span>
                </a>
            </div>
            <div class="modal-body">
                <h2 class="mat-title mb-2">{{ratePlan.costOfStay | currency: 'GBP'}}</h2>

                <div class="row">
                    <div class="col-12 col-md-8">
                        <!-- <p>{{ratePlan.overview}}</p> -->
                         <div style="font-size: 13px;">
                            <div class="p-2" [innerHTML]="ratePlan.description"></div>

                            <h2 class="mat-title mb-2">Terms and conditions</h2>

                            <div [innerHTML]="ratePlan.terms"></div>

                         </div>

                    </div>
                    <div class="col-12 col-md-4">
                        <img style="max-width: 100%;" [src]="ratePlan.imageUrl" />
                    </div>
                </div>


            </div>
        </div>
    </div>
</div>



<div class="modal-backdrop fade show modal-slide-show"></div>
