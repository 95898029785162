import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BasketTotalsService } from 'src/app/services/basket-totals.service';
import { BasketService } from 'src/app/services/basket.service';
import { BookingService } from 'src/app/services/booking.service';
import { Store } from 'src/app/store';

@Component({
    selector: 'app-booking-giftcard-entry',
    templateUrl: './booking-giftcard-entry.component.html',
    styleUrls: ['./booking-giftcard-entry.component.css']
})
export class BookingGiftcardEntryComponent implements OnInit {
    @Input() basket;
    @Output() onClose: EventEmitter<any> = new EventEmitter();

    processing = false;
    cardError = '';
    giftcards = [];

    giftCard = {
        bookingIdRef: 0,
        pin: '',
        pointsTotal: 0,
        valueTotal: 0,
        pointsToRedeem: 0,
        valueToRedeem: 0,
        number: ''
    }

    cardResponse;

    constructor(
        private bookingService: BookingService,
        private basketService: BasketService,
        private basketTotalsService: BasketTotalsService,
        private store: Store,
    ) { }

    ngOnInit(): void {
        this.basket.totals.giftCard = undefined;

        // this.basket.totals.giftCardDiscount = 0;
    }

    toggleGiftCard() {
        this.onClose.emit();
    }

    giftCardCheckPoints() {
        let okToApply = true;
        this.cardError = '';
        this.processing = true;
        if (this.basket.items[0].booking.hotelId === 'HOTEL001') {
            if (this.giftCard.number.substring(0, 12) !== '633698201635' && this.giftCard.number.substring(0, 14) !== '63369820160035') {
                okToApply = false;
                this.cardError = 'Gift card not valid for this hotel';
                this.processing = false;
            }
        }
        if (this.basket.items[0].booking.hotelId === 'HOTEL002') {
            if (this.giftCard.number.substring(0, 12) !== '633698201638' && this.giftCard.number.substring(0, 14) !== '63369820160038') {
                okToApply = false;
                this.cardError = 'Gift card not valid for this hotel';
                this.processing = false;
            }
        }
        if (this.basket.items[0].booking.hotelId === 'HOTEL003') {
            if (this.giftCard.number.substring(0, 12) !== '633698201637' && this.giftCard.number.substring(0, 14) !== '63369820160037') {
                okToApply = false;
                this.cardError = 'Gift card not valid for this hotel';
                this.processing = false;
            }
        }
        if (this.basket.items[0].booking.hotelId === 'HOTEL005') {
            if (this.giftCard.number.substring(0, 12) !== '633698201639' && this.giftCard.number.substring(0, 14) !== '63369820160039') {
                okToApply = false;
                this.cardError = 'Gift card not valid for this hotel';
                this.processing = false;
            }
        }

        if (okToApply) {
            for (let i = 0; i < this.basket.giftCards.length; i++) {
                if (this.giftCard.number === this.basket.giftCards[i].number) {
                    okToApply = false;
                    this.cardError = 'Gift card already applied';
                    this.processing = false;
                }
            }
        }

        if (okToApply) {
            if (!this.giftCard.pin) {
                okToApply = false;
                this.cardError = 'Please enter PIN number';
                this.processing = false;
            }
        }
        if (okToApply) {
            this.bookingService.giftCardCheckPoints(this.giftCard).subscribe(data => {
                if (data.data.card) {
                    this.cardResponse = data.data.card;
                } else {
                    this.cardError = data.data.description;
                }
                this.processing = false;
            })

        }
    }

    applyPoints() {
        this.cardError = '';
        let okToApply = true;

        this.giftCard.pointsToRedeem = Math.ceil(+this.giftCard.valueToRedeem * +this.cardResponse.BalanceFactor);



        if (+this.giftCard.pointsToRedeem > +this.cardResponse.Balance) {
            okToApply = false;
            this.cardError = 'Balance too low - please amend value';
        }
        if (+this.giftCard.valueToRedeem > (+this.basket.checkoutTotals.costs.total - +this.basket.checkoutTotals.discount.total)) {
            okToApply = false;
            this.cardError = 'Points value exceeds booking value';
        }

        if (okToApply) {
            if (!this.basket.giftCards) { this.basket.giftCards = [] };
            this.giftCard.valueToRedeem = +this.giftCard.valueToRedeem;

            this.basket.giftCards.push(this.giftCard);
            this.basket.giftCardTotal = 0;
            // this.basket.totals.giftCardDiscount = 0;

            // for (let i = 0; i < this.basket.giftCards.length; i++) {
            //     this.basket.giftCardTotal = +this.basket.giftCardTotal + +this.basket.giftCards[i].valueToRedeem;
            //     this.basket.checkoutTotals.paid.giftCard = +this.basket.checkoutTotals.paid.giftCard + +this.basket.giftCards[i].valueToRedeem;
            // }
            // if (+this.basket.checkoutTotals.paid.giftCard > (+this.basket.checkoutTotals.costs.total - +this.basket.checkoutTotals.discount.total)) {
            // }

            this.basket = this.basketTotalsService.totalBasketMultiCheckout(this.basket);
            this.basketService.saveBasket(this.basket);
            this.onClose.emit();
        }
    }

    testGiftCard() {
        this.cardError = '';
        let okToApply = true;

        this.giftCard.pointsToRedeem = 5000;
        this.giftCard.valueToRedeem = 50;

        if (okToApply) {
            if (!this.basket.giftCards) { this.basket.giftCards = [] };
            this.giftCard.valueToRedeem = +this.giftCard.valueToRedeem;

            this.basket.giftCards.push(this.giftCard);
            this.basket.giftCardTotal = 0;
            this.basket.totals.giftCardDiscount = 0;

            for (let i = 0; i < this.basket.giftCards.length; i++) {
                this.basket.giftCardTotal = +this.basket.giftCardTotal + +this.basket.giftCards[i].valueToRedeem;
                this.basket.totals.giftCardDiscount = +this.basket.totals.giftCardDiscount + +this.basket.giftCards[i].valueToRedeem;
            }
            if (+this.basket.totals.giftCardDiscount > (+this.basket.total - +this.basket.discount)) {
            }
            this.basket.totals.giftCard = this.giftCard;
            // this.basket.totals.giftCardDiscount = this.giftCard.valueToRedeem;

            this.basket = this.basketTotalsService.totalBasket(this.basket);
            this.basketService.saveBasket(this.basket);
            this.onClose.emit();
        }

    }
}
