import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AdyenService } from '../services/adyen.service';
import { ToolsService } from '../services/tools.service';

@Component({
    selector: 'app-adyen-refund',
    templateUrl: './adyen-refund.component.html',
    styleUrls: ['./adyen-refund.component.css']
})
export class AdyenRefundComponent implements OnInit {
    @Input() refundData;
    @Output() complete = new EventEmitter();

    refundValueToDisplay = 0;

    error = '';
    success = '';

    processing = false;
    processed = false;


    constructor(
        private adyenService: AdyenService,
        private toolsService: ToolsService,
    ) { }

    ngOnInit() {
        console.log(this.refundData)

        this.refundValueToDisplay = +this.refundData.payment.paid;
    }

    refund() {
        this.processing = true;
        this.error = '';

        const dataToSend = {
            bookingLink: this.toolsService.newUUID(),
            bookingId: this.refundData.bookingId,
            merchantAccount: this.refundData.payment.merchantAccountCode,
            value: Math.round((+this.refundValueToDisplay * 100) * 100) / 100,
            pspReference: this.refundData.payment.pspReference,
        }
        console.log(dataToSend);
        this.adyenService.refund(dataToSend).subscribe(data => {
            console.log(data)
            this.processing = false;
            if (data.status === 'rejected') {
                this.error = 'Sorry - the merchant rejected the refund request';
            } else {
                this.processed = true;
                this.success = 'Your refund request has been accepted';
            }
        })
    }

    confirm(refundResult) {
        this.complete.emit({ action: true, refundResult });
    }

    close() {
        this.complete.emit({ action: false });
    }
}
