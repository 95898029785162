import { Component, OnDestroy, OnInit } from '@angular/core';
import { BookingService } from '../../services/booking.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AdyenService } from 'src/app/services/adyen.service';
import { Store } from 'src/app/store';
import { ToolsService } from 'src/app/services/tools.service';

@Component({
    selector: 'app-booking-view',
    templateUrl: './booking-view.component.html',
    styleUrls: ['./booking-view.component.css']
})
export class BookingViewComponent implements OnInit, OnDestroy {
    overlays$ = this.store.select<any>('overlays');
    cancelling = false;

    showRefundModal = false;
    showPaymentModal = false;
    paymentLinkData = undefined;
    paymentData: any = {};
    refundData: any = {};
    payAmountLocked = false;

    user$ = this.store.select<any>('user');


    id = '';
    booking: any;
    spaBooking: any;
    cards = [];
    cardIdx = 0;

    cardSelected = undefined;
    requestingPayment = false;
    processingPayment = false;
    amountToPay = 0;
    paymentResult = undefined;

    confirmationData = {
        open: false,
        buttonText: 'Confirm',
        title: '',
        detail: '',
        data: undefined,
    };

    intervalId: any;

    constructor(
        private bookingService: BookingService,
        private adyenService: AdyenService,
        private router: Router,
        private route: ActivatedRoute,
        private store: Store,
        private toolsService: ToolsService,
    ) { }

    ngOnInit() {
        this.id = this.route.snapshot.params['id'];
        this.get();
        this.start();
    }

    ngOnDestroy() {
        this.end();
    }
    start() {
        this.intervalId = setInterval(() => {
            this.refreshBooking()
        }, 10000);

    }
    end() {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    refreshBooking() {
        this.bookingService.get(this.id).subscribe(data => {
            this.booking.paymentHistory = data.paymentHistory;
            this.booking.payment = data.payment;
            this.amountToPay = data.payment.gross - data.payment.deposit;

            this.getUserCards();
            if (!this.payAmountLocked) {
                this.paymentData.valueToDisplay = this.amountToPay;
            }
        });
    }

    get() {
        this.bookingService.get(this.id).subscribe(data => {
            this.booking = data;
            this.booking.confirmed = false;
            this.amountToPay = this.booking.payment.unpaid;

            this.getUserCards();
            console.log(this.booking.trybeOrderId)
            if (this.booking.trybeOrderId) {
                this.getSpaOrder(this.booking.trybeOrderId);
            }
            console.log(this.booking)
            this.paymentData.valueToDisplay = this.amountToPay;
            this.paymentData.shopper = {
                id: this.booking.customer.id,
                name: `${this.booking.customer.firstname} ${this.booking.customer.surname}`,
                email: this.booking.customer.email,
                firstname: this.booking.customer.firstname,
            };

            this.paymentData.user = this.booking.customer;
            this.paymentData.hotel = { id: this.booking.hotel.id, name: this.booking.hotel.name },
                this.paymentData.room = { id: this.booking.room.id, name: this.booking.room.name },
                this.paymentData.reference = `${this.booking.hotel.name} room booking`,
                this.paymentData.isBooking = true;

        });
    }

    getBooking(Id) {
        this.id = Id;
        this.get();
    }

    getSpaOrder(trybeOrderId) {
        this.bookingService.getSpaOrder(trybeOrderId).subscribe(data => {
            console.log(data.data.data);
            this.spaBooking = data.data.data;
        })
    }

    getUserCards() {
        const d = {
            userId: this.booking.customer.id,
            hotelId: this.booking.hotel.id,
            roomId: this.booking.room.id
        }
        this.adyenService.getUserCards(d).subscribe(data => {
            this.cards = data;
        })
    }

    cancelConfirm() {
        this.confirmationData.title = 'Confirm Cancellation';
        this.confirmationData.data = {};
        this.confirmationData.detail = 'Please confirm you would like to cancel this booking';
        this.confirmationData.open = true;
    }

    confirmAction(e) {
        if (e.action) {
            this.cancelling = true;
            this.bookingService.cancelBooking(this.booking.id).subscribe(data => {
                console.log(data)
                this.booking.resStatus = 'Cancelled';
                this.cancelling = false;
            });
        }
        this.confirmationData.open = false;
    }

    createBookingLink(metadata) {

        this.adyenService.createBookingLink(metadata).subscribe(data => {
            console.log(data)
        })
    }


    setPaymentMethod(method) {
        this.end();
        this.paymentData.sendBookingLink = true;

        // this.paymentData.valueToDisplay = '1.00';

        this.paymentData.value = Math.round((+this.paymentData.valueToDisplay * 100) * 100) / 100;

        console.log(this.paymentLinkData)

        this.paymentData.lineItems = [
            { quantity: 1, amountIncludingTax: this.paymentData.value, description: this.booking.hotel.name + ' ' + this.booking.room.name }
        ];

        this.paymentData.method = method;
        this.paymentData.value = Math.round((+this.paymentData.valueToDisplay * 100) * 100) / 100;
        this.paymentData.metadata = {
            bookingId: this.booking.id,
            bookingLink: this.toolsService.newUUID(),
            method: method
        }

        console.log(this.paymentData)
        this.showPaymentModal = true;

    }
    lockPayAmount() {
        this.payAmountLocked = true;
    }
    paymentComplete(e) {
        this.payAmountLocked = false;
        this.refreshBooking();
        this.start();
        console.log(e)
        this.showPaymentModal = false;
        if (e.action) {
            // this.createBookingLink(e.paymentResult.metadata);
        }
    }

    triggerRefund(payment) {
        console.log(payment)
        this.end();
        this.refundData.refundValue = payment.paid;
        this.refundData.payment = payment;
        this.refundData.bookingId = this.booking.id;
        this.showRefundModal = true;
    }

    refundComplete(e) {
        this.refreshBooking();
        this.start();
        console.log(e)
        this.showRefundModal = false;
        if (e.action) {
            // this.createBookingLink(e.paymentResult.metadata);
        }
    }

}
