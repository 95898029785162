import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AvailabilityService } from 'src/app/services/availability.service';
import { BasketService } from 'src/app/services/basket.service';
import { OverlayService } from 'src/app/services/overlay.service';
import { Store } from 'src/app/store';

@Component({
    selector: 'app-room-select',
    templateUrl: './room-select.component.html',
    styleUrls: ['./room-select.component.css']
})
export class RoomSelectComponent implements OnInit {

    basket = undefined;
    basketMeta = undefined;

    booking$ = this.store.select<any>('basketBooking');
    availabilitySearch$ = this.store.select<any>('availabilitySearch')
    infoPlan$ = this.store.select<any>('infoPlan')
    overlays$ = this.store.select<any>('overlays')

    ratePlans = [];
    addOns = [];
    guests = [];

    constructor(
        private store: Store,
        private router: Router,
        private availabilityService: AvailabilityService,
        private basketService: BasketService,
        private overlayService: OverlayService,
    ) { }

    ngOnInit() {
        this.basketMeta = this.store.selectForLocal('basketMeta');

        this.basket = this.basketService.getBasket();
        const availabilitySearch = this.store.selectForLocal('availabilitySearch');

        this.getPricing()

        // this.loadRates('BB')

        const totalGuests = +availabilitySearch.adults + +availabilitySearch.children;

        for (let g = 1; g <= totalGuests; g++) {
            const guest = {
                firstname: '',
                surname: '',
                telephone: '07469757202',
                email: '',
                add1: '1 Peacock Road',
                add2: 'Bromham',
                add3: '',
                town: 'Bedford',
                county: 'Bedfordshire',
                country: 'GB',
                postcode: 'MK43 8TG'
            }
            if (g === 1) {
                guest.firstname = this.basket.user.firstname;
                guest.surname = this.basket.user.surname;
                guest.telephone = this.basket.user.telephone;
                guest.email = this.basket.user.username;
            }
            this.guests.push(guest);
        }
    }


    getPricing() {
        const availabilitySearch = this.store.selectForLocal('availabilitySearch');
        const basketBooking = this.store.selectForLocal('basketBooking');

        this.availabilityService.loadAvailability(availabilitySearch).subscribe(data => {
            console.log(data);
            const booking = this.store.selectForLocal('basketBooking')


            const ratePlans = this.store.selectForLocal('ratePlans');
            console.log(ratePlans)
            if (ratePlans.length) {
                if (basketBooking.planSelected) {
                    booking.planSelected = basketBooking.planSelected;
                    booking.roomPricing.roomCostForStay = basketBooking.planSelected.costOfStay;
                } else {
                    booking.planSelected = ratePlans[0];
                    booking.roomPricing.roomCostForStay = ratePlans[0].costOfStay;
                }
                console.log(booking)
            }
            this.ratePlans = ratePlans;
            this.store.set('basketBooking', booking)

        })

    }
    selectRatePlan(ratePlan) {
        const booking = this.store.selectForLocal('basketBooking')
        // this.planSelected = ratePlan;

        booking.planSelected = ratePlan;
        booking.roomPricing.roomCostForStay = ratePlan.costOfStay;
        console.log(booking)

        this.store.set('basketBooking', booking)
    }

    loadRates(ratePlanCode) {
        const availabilitySearch = this.store.selectForLocal('availabilitySearch');
        this.store.set('ratePlansUpsell', []);

        this.availabilityService.loadRates('searchbar.component.ts').subscribe(data => {
            // this.ratePlans = data.ratePlans;
            for (let i = 0; i < data.ratePlans.length; i++) {
                if (ratePlanCode === data.ratePlans[i].ratePlanCode) {
                    const booking = this.store.selectForLocal('basketBooking');
                    booking.roomPricing.roomCostForStay = data.ratePlans[i].costOfStay;
                    booking.planSelected = data.ratePlans[i];
                    this.store.set('basketBooking', booking);

                }
            }
        })
    }

    loadUpsellRates() {
        this.availabilityService.loadUpsellRates('searchbar.component.ts').subscribe(data => {
        })
    }
    viewRate(ratePlan) {
        this.store.set('infoPlan', ratePlan)
        this.overlayService.open('ratePlanInfo')

    }

    addToBasket() {
        console.log(this.addOns);
        const booking = this.store.selectForLocal('basketBooking');
        const availabilitySearch = this.store.selectForLocal('availabilitySearch');

        availabilitySearch.addOns = this.addOns;
        console.log(availabilitySearch)

        booking.guests = this.guests;

        const basketToSaveToDB = this.basketService.addToBasket(availabilitySearch, booking);
        console.log(basketToSaveToDB)
        this.basketService.saveBasketToDB(basketToSaveToDB).subscribe(data => {
            this.router.navigate(['/bookings/create/payment'])
        })
    }
}
