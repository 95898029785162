<div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Refunding {{refundValueToDisplay | currency: 'GBP'}}</h5>
                <a class="close hover" (click)="close()">
                    <span aria-hidden="true">&times;</span>
                </a>
            </div>
            <div class="modal-body">
                <div class="text-center">

                    <div class="mt-3" *ngIf="!processed">
                        <div class="form-group row mt-4">
                            <label class="col-sm-3 col-form-label">Refund value (£)</label>
                            <div class="col-sm-9">
                                <input type="text" class="form-control form-control-sm" [(ngModel)]="refundValueToDisplay" />
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-6">
                                <button class="btn btn-sm btn-warning" (click)="close()">Cancel</button>
                            </div>
                            <div class="col-6 text-right">
                                <button *ngIf="!processing" class="btn btn-sm btn-primary" (click)="refund()">Submit</button>
                                <button *ngIf="processing" class="btn btn-sm btn-primary">
                                    Submit
                                    <i class="fa fa-spin fa-spinner ml-1"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="error" class="alert alert-warning mt-2">
                        <p class="mb-0">{{error}}</p>
                    </div>
                    <div *ngIf="success" class="alert alert-success mt-2">
                        <p class="mb-0">{{success}}</p>
                    </div>


                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-backdrop fade show modal-slide-show"></div>


